let baseURL = '';
if (process.env.NODE_ENV === 'staging') {
	//baseURL = ''; // 服务器测试
	//baseURL = ''; // 服务器正式
	baseURL = 'http://106.55.198.230:48080/';
} else {
	// baseURL = 'http://172.16.10.9:48099/'; // 后端本地
	// baseURL = 'http://106.55.198.230:48080/';
	// baseURL = 'http://print-apitwo.rockb2b.com/'; // 服务器正式
	
	baseURL = 'https://print-api.rockb2b.com/'; // 服务器正式
}

module.exports = {
	customerStatus: baseURL + 'app-api/printer/h5/getSellerDetail', // 通过sn查客户详情
	getBrandList: baseURL + 'app-api/printer/h5/listBrand', // 获取品牌列表
	getModelList: baseURL + 'app-api/printer/h5/listModel', // 获取型号列表
	listGallery: baseURL + 'app-api/printer/h5/listGallery', // 获取图库列表
	listPicture: baseURL + 'app-api/printer/h5/listPicture', // 获取图片列表
	createOrder: baseURL + 'app-api/printer/h5/createOrder', // 生成订单
	getOrder: baseURL + 'app-api/printer/h5/getOrderDetail', // 查询订单信息
	searchBrandModels: baseURL + 'app-api/printer/h5/h5FuzzyQueryBrandModels', // 获取搜索数据
	getFontList: 'https://api.rockb2b.com/flexible/v1/web/user/u/font/listUsable', // 获取字体
	orderExpire: baseURL + 'app-api/printer/h5/cancelOrder', // 订单作废
	queryPrinterMobileHome: baseURL + 'app-api/printer/h5/getH5HomeConfig', // 获取首页配置
	hotPrintImgParams: baseURL + 'app-api/printer/h5/get' //获得纸张配置

}


