module.exports = {
	C: {
		Next: '下一步',
		Copyright: '官方',
		Tips: '提示：红色虚线框内为图片打印区域；',
		Tips2: '手机线框图内为成品预览效果；',
		Print: '印刷效果',
		PResult1: '一般',
		PResult2: '模糊',
		PResult3: '清晰',
		NoData: '暂无数据',
		ChooseModel: '选择手机型号',
		HadChooseModel: '更换机型',
		Cancel: '取消',
		Confirm: '确定',
		Restore: '复原',
		Return: '返回',
		Warning: '警告',
		GongNeng: '调整',
		TuKu: '图库',
		Photo: '传图',
		Sticker: '贴纸',
		Text: '文字',
		AddText: '添加文字',
		PleaseChoose: '请选择字体',
		PleaseChooseText: '请输入文字内容',
		PictureEdit: '调整图片',
		WarningTips: '请上传格式为image/png, image/jpg, image/jpeg的图片',
		UploadLimit: '上传图片大小不能超过 30MB!',
		ModelTips: '请先选择机型!',
		PicTips: '请先上传图片!',
		PicChooseTips: '请先选中要调整的图片',
		PicMake: '作品制作中',
		PicLoad: '作品上传中',
		Loading: '载入中',
		Reminder: '温馨提示',
		UploadTips0: '请阅读并勾选',
		UploadTips1: '请确保上传的图片清晰可用，不可上传违法、违规图片。请仔细阅读',
		UploadTips2_1: '《手机贴纸图案定制协议》',
		UploadTips2_2: '《手机壳图案定制协议》',
		UploadTips3: '，点击“同意”表示您已阅读并同意该协议。',
		Back: '不同意',
		Know: '同意并继续',
		PhoneProtocol: '图案定制协议',
		NoPic: '没有更多图片哦!',
		NoSn: '设备信息不存在',
		NoMid: '材质信息不存在',
		CusDisabled: '客户已被禁用',
		NoCustomerInfo: '客户信息不存在',
		SnDisabled: '设备异常，请联系客服！',
		NoSystem: '暂无任何配置',
		Loadingg: '载入中',
		GeneratingOrders: '正在生成订单',
		DownloadInfo: '请截图保存图片或使用浏览器打开保存图片',
		DownloadLoading: '保存图片中',
		DownloadSuccess: '图片已成功保存到相册',
		PhotoChange: '由于机型改变，请重新选择图片',
		SelectPicture: '选图',
		MapTransfer: '传图',
		ClosePicture: '关闭',
		LoadingResource: '资源正在加载中',
		NoModelList: "无任何机型数据，请重新输入",
		NoModelLists: "暂无任何型号数据",
		BrandName: "品牌名称",
		JixinName: "机型名称",
		SearchContent: '搜索手机型号',
		PopularModels: '品牌机型',
		SuccessRestore: '复原成功',
		SuccessRestoreRotate: '旋转角度初始化成功',
		FunctionNoOpen: '该功能正在开发中',
		Rotate: '旋转',
		Scale: '缩放',
		Acoustic: '镜像',
		RotateLeft15: '逆15度',
		RotateRight15: '顺15度',
		ReadAndCheckTheAgreement: '请先阅读并勾选协议',
		FlipHorizontal: '水平翻转',
		FlipVertical: '垂直翻转',
		FlipHorizontalYes: '已开启水平翻转',
		FlipHorizontalNo: '已关闭水平翻转',
		FlipVerticalYes: '已开启垂直翻转',
		FlipVerticalNo: '已关闭垂直翻转'
	},
	P: {
		ConfirmPrint: '确认打印',
		PrintPreview: '效果图预览',
		Model: '手机型号',
		Remark: '备注信息，如：手机号、邮箱地址，以便订单查询',
		SubmitPrint: '提交打印'
	},
	S: {
		SubmitSuccess: '提交成功啦~',
		PrintInfo: '您的订单信息',
		PrintSceen: '请截图保存，并出示给店员打印',
		PrintNo: '订单号码',
		Save: '保存至相册',
		CancelOrder: '订单作废',
		CancelOrderSuccess: '订单作废成功',
		CancelOrderFail: '订单作废失败，请联系客服',
		ScreenshotSave: '请使用手机截图保存订单信息',
		copySuccess: '复制成功',
		copyError: '复制失败',
		modelTipText: '网络异常，请重新生成订单',
	}
}
