<template>
	<div class="page-container">
		<div class="preview-wrap">
			<div class="img-wrap" id="imgWrap" v-if="isHT == 1">
				<!-- 打印图片 -->
				<img class="img" :src="imgUrl" />
			</div>
			<div class="img-wrap2" id="imgWrap" v-else>
				<!-- 打印图片 -->
				<img class="img2" :src="imgUrl" mode="widthFix" />
			</div>
			<div id="previewImg">
				<div class="model">{{ modelName }}</div>
				<div class="desc">{{ $t("P.PrintPreview") }}</div>
			</div>
		</div>
		<div class="remark-box" id="remarkBox">
			<van-field v-model="remark" rows="3" autosize type="textarea" :placeholder="$t('P.Remark')" />
		</div>
		<div class="btn-box" id="btnnn">
			<div class="btn-submit" @click="handleSubmit">
				{{ $t("P.SubmitPrint") }}
			</div>
		</div>

		<!-- 提示 -->
		<Loading v-show="isLoading" :message="message"></Loading>
	</div>
</template>

<script type="text/ecmascript-6">
	import Loading from "components/loading/loading";
	import api from "api/allApi";
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				isWeiXinFlag: true, // 判断是否是微信浏览器
				isLoading: false,
				message: "",
				isHT: 1, // 编辑器类型 1-汉图 2-热转印
				mid: 1, // 材质编号
				sizeId: 1, // 纸张编号
				remark: "", // 备注
				imgUrl: "",
				pdfUrl: "",
				pdfUrl2: ""
			};
		},
		computed: {
			...mapGetters([
				"devSn",
				"picId",
				"modelId",
				"modelName"
			]),
		},
		components: {
			Loading
		},
		beforeCreate() {
			this.isLoading = true;
			this.message = this.$t("C.Loadingg");
		},
		created() {
			this.isHT = localStorage.getItem("isHT");
			this.mid = localStorage.getItem("mid");
			this.imgUrl = this.$route.query.diyPic;
			this.pdfUrl = this.$route.query.diyPdf;
			this.pdfUrl2 = this.$route.query.diyPdf2;
			this.isLoading = false;
		},
		activated() {
			this.isHT = localStorage.getItem("isHT");
			this.mid = localStorage.getItem("mid");
			this.imgUrl = this.$route.query.diyPic;
			this.pdfUrl = this.$route.query.diyPdf;
			this.pdfUrl2 = this.$route.query.diyPdf2;
			this.isLoading = false;
		},
		mounted() {
			var height = document.documentElement.clientHeight;
			this.isWeiXinFlag = this.isWeiXin();
			if (this.isWeiXinFlag == true) {
				if (height <= 553) {
					document.getElementById("imgWrap").style.height = "20rem"
					document.getElementById("remarkBox").style.position = "relative"
					document.getElementById("remarkBox").style.top = "20px"
				} else {
					document.getElementById("imgWrap").style.height = "31.5rem"
				}
			} else {
				if (height <= 553) {
					document.getElementById("imgWrap").style.height = "20rem"
					document.getElementById("remarkBox").style.position = "relative"
					document.getElementById("remarkBox").style.top = "20px"
				} else {
					document.getElementById("imgWrap").style.height = "28rem"
					document.getElementById("previewImg").style.position = "relative"
					document.getElementById("previewImg").style.top = "30px"
					document.getElementById("remarkBox").style.position = "relative"
					document.getElementById("remarkBox").style.top = "15px"
				}
			}
		},

		methods: {
			handleSubmit() {
				this.isLoading = true;
				this.message = this.$t("C.GeneratingOrders");

				let devSn;
				let phoneModeId;
				if (this.devSn === "" || this.devSn === null || this.devSn === undefined) {
					devSn = localStorage.getItem("devSn");
				} else {
					devSn = this.devSn;
				}
				if (this.modelId === "" || this.modelId === null || this.modelId === undefined) {
					if(localStorage.getItem("modelId") === "" || localStorage.getItem("modelId") === null || localStorage.getItem("modelId") === undefined) {
						if(sessionStorage.getItem("modelId") === "" || sessionStorage.getItem("modelId") === null || sessionStorage.getItem("modelId") === undefined) {
							this.$toast(this.$t("S.modelTipText"));
							this.isLoading = false;
							return
						}else {
							phoneModeId = sessionStorage.getItem("modelId");
						}
					}else {
						phoneModeId = localStorage.getItem("modelId");
					}
				} else {
					phoneModeId = this.modelId;
				}

				if (this.mid == 1) {
					this.sizeId = 1;
				} else {
					this.sizeId = 2;
				}

				this.$api
					.post(this, api.createOrder, {
						materialId: this.mid,
						paperId: this.sizeId,
						deviceSn: devSn,
						modelId: phoneModeId,
						imgUrl: this.pdfUrl, // 生产文件100
						imgBox: this.pdfUrl2, // 生产文件105.5
						pictureId: this.picId
					})
					.then((res) => {
						if (res.code === 0) {
							this.isLoading = false;
							let orderNo = res.data;
							this.$router.push({
								path: "/success",
								query: {
									no: orderNo,
									diyPic: this.imgUrl,
									diyPdf: this.pdfUrl,
									diyPdf2: this.pdfUrl2
								}
							});
						} else {
							this.$toast(this.$t("C.SnDisabled"));
						}
					});
			},
			isWeiXin() {
				var ua = window.navigator.userAgent.toLowerCase();
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					return true;
				} else {
					return false;
				}
			}
		}
	};
</script>

<style rel="stylesheet/stylus" lang="stylus" scoped>
	@import '~common/styles/mixin.styl';
	@import '~common/styles/variable.styl';

	.page-container {
		padding-bottom: 4rem;
		background-color: $color-font-white;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		&::-webkit-scrollbar {
			display: none;
		}

		.preview-wrap {
			width: 100%;
			padding: 3.6rem 0 3.8rem;
			box-sizing: border-box;

			.img-wrap {
				position: relative;
				margin: 0 auto;
				width: 17.5rem;
				height: 31.5rem;
			}

			.img {
				display: inline-block;
				width: 17.5rem;
				height: 31.5rem;
			}

			.print-t {
				position: absolute;
				top: 0;
				left: 0;
				height: 31.5rem;
			}

			// 展示不带左侧条子的图
			.img-wrap2 {
				position: relative;
				margin: 0 auto;
				width: 19rem;
				height: 20rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.img2 {
				display: block;
				width: 85%;
				height: auto;
			}

			.model {
				margin-top: 2.2rem;
				font-size: $font-base;
				line-height: 1.7rem;
				font-weight: bold;
			}

			.desc {
				margin-top: 1.4rem;
				font-size: $font-lg;
				color: $color-font-base;
				line-height: 2.2rem;
			}
		}

		.remark-box {
			margin: 0 2rem;

			>>>.van-cell {
				font-size: $font-sm;
				border-radius: 0.4rem;
				background-color: $color-bg-text;
			}
		}

		.btn-box {
			margin-top: 3.6rem;


			.btn-submit {
				position: relative;
				btn-normal();
				width: 30rem;
				lineHeight(5rem);
				color: #ffffff !important;

				.tip {
					font-size: $font-sm;
				}
			}
		}
	}
</style>
