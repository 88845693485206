module.exports = {
	C: {
		Next: 'التالي',
		Copyright: 'رسمي',
		Tips: 'تلميحات: المربع الأحمر المنقط هو المنطقة التي سيتم طباعة الصورة فيها',
		Tips2: 'صورة الإطار السلكي للهاتف عبارة عن معاينة للمنتج النهائي',
		Print: 'نتيجة الطباعة',
		PResult1: 'طبيعي',
		PResult2: 'طمس',
		PResult3: 'واضح',
		NoData: 'لايوجد بيانات',
		ChooseModel: 'اختر طراز الهاتف',
		HadChooseModel: 'تغيير النموذج',
		Cancel: 'يلغي',
		Confirm: 'يتأكد',
		Restore: 'يعيد',
		Return: 'يعود',
		Warning: 'تحذير',
		GongNeng: 'مخصص',
		TuKu: 'صالة عرض',
		Photo: 'صورة',
		Sticker: 'ملصق',
		Text: 'نص',
		AddText: 'أضف نصًا',
		PleaseChoose: 'الرجاء تحديد خط',
		PleaseChooseText: 'الرجاء إدخال محتوى النص',
		PictureEdit: 'ضبط الصورة',
		WarningTips: 'ضبط الصورة',
		UploadLimit: 'لا يمكن أن يتجاوز حجم تحميل الصورة 30 ميغا بايت!',
		ModelTips: 'الرجاء اختيار النموذج أولا!',
		PicTips: 'الرجاء تحميل الصورة أولا!',
		PicChooseTips: 'يرجى تحديد الصورة المراد تعديلها أولاً',
		PicMake: 'إنتاج',
		PicLoad: 'تحميل',
		Loading: 'تحميل',
		Reminder: 'تذكير',
		UploadTips0: 'يرجى قراءة والتحقق من',
		UploadTips1: 'يرجى التأكد من أن الصور التي تم تحميلها واضحة وقابلة للاستخدام ، لا يمكن تحميل الصور غير القانونية وغير القانونية . يرجى قراءة بعناية',
		UploadTips2_1: '" الهاتف المحمول ملصق نمط مخصص الاتفاق "</span>انقر على " موافق "',
		UploadTips2_2: '" الهاتف المحمول شل نمط التخصيص البروتوكول "</span>انقر على " موافق "',
		UploadTips3: 'انقر على " موافق " للإشارة إلى أن كنت قد قرأت ووافقت على الاتفاق .',
		Back: 'تعارض',
		Know: 'وافق واستمر',
		PhoneProtocol: 'بروتوكول تخصيص النمط',
		NoPic: 'لا مزيد من الصور!',
		NoSn: 'معلومات الجهاز غير موجودة',
		NoMid: 'المعلومات الجوهرية غير موجودة',
		CusDisabled: 'تم تعطيل العميل',
		NoCustomerInfo: 'معلومات العميل غير موجودة',
		SnDisabled: 'خلل في الجهاز يرجى الاتصال بخدمة العملاء!',
		NoSystem: 'لا يوجد تكوين حتى الآن',
		Loadingg: 'تحميل',
		GeneratingOrders: 'توليد الأوامر',
		DownloadInfo: 'يرجى التقاط لقطة شاشة لحفظ الصورة أو استخدام المتصفح لفتح الصورة وحفظها',
		DownloadLoading: 'حفظ الصور',
		DownloadSuccess: 'تم حفظ الصورة بنجاح في الألبوم',
		PhotoChange: 'يرجى إعادة تحديد الصورة بسبب تغيير النموذج',
		SelectPicture: 'حدد الصورة',
		MapTransfer: 'تحميل الصورة',
		ClosePicture: 'يغلق',
		LoadingResource: 'يتم تحميل الموارد',
		NoModelList: "لا توجد بيانات نموذجية ، يرجى إعادة الإدخال",
		NoModelLists: "لا توجد بيانات نموذجية",
		BrandName: "اسم العلامة التجارية",
		JixinName: "اسم النموذج",
		SearchContent: 'ابحث عن طراز الهاتف المحمول',
		PopularModels: 'نموذج العلامة التجارية',
		SuccessRestore: 'نجح الاسترداد',
		SuccessRestoreRotate: 'نجح تهيئة زاوية الدوران',
		FunctionNoOpen: 'هذه الوظيفة قيد التطوير',
		Rotate: 'تدور',
		Scale: 'حجم',
		Acoustic: 'صوتي',
		RotateLeft15: '15 درجة',
		RotateRight15: '15 درجة',
		ReadAndCheckTheAgreement: 'يرجى قراءة والتحقق من الاتفاقية أولا',
		FlipHorizontal: 'دوران أفقي',
		FlipVertical: 'قلب عمودي',
		FlipHorizontalYes: 'تحول أفقي',
		FlipHorizontalNo: 'تحول أفقي قبالة',
		FlipVerticalYes: 'تحول عمودي',
		FlipVerticalNo: 'عمودي قلب مغلق'
	},
	P: {
		ConfirmPrint: 'قم بتأكيد الطباعة',
		PrintPreview: 'تقديم المعاينة',
		Model: 'طراز الهاتف',
		Remark: 'معلومات الملاحظة ، مثل: رقم الهاتف وعنوان البريد الإلكتروني للاستعلام عن الطلب',
		SubmitPrint: 'أرسل للطباعة'
	},
	S: {
		SubmitSuccess: 'أرسل بنجاح ~',
		PrintInfo: 'معلومات طلبك',
		PrintSceen: 'يرجى التقاط لقطة شاشة لحفظها وإظهارها للموظفين للطباعة',
		PrintNo: 'طباعة لا',
		Save: 'حفظ في [الصور]',
		CancelOrder: 'الغاء الطلب',
		CancelOrderSuccess: 'تم إلغاء الطلب بنجاح',
		CancelOrderFail: 'فشل إلغاء الطلب. يرجى الاتصال بخدمة العملاء',
		ScreenshotSave: 'يرجى استخدام الهاتف المحمول لقطة لإنقاذ النظام',
		copySuccess: ' نسخة ناجحة ',
		copyError: 'فشل النسخ',
		modelTipText: ' شبكة استثناء ، يرجى إعادة بناء النظام ',
	}
}
