module.exports = {
	C: {
		Next: 'Siguiente',
		Copyright: 'Oficial ',
		Tips: 'Consejo: El cuadrado rojo punteado es el área donde se imprimirá la imagen;',
		Tips2: 'La imagen del marco del telefono es una vista previa del producto terminado;',
		Print: 'Resultado de impresión',
		PResult1: 'Normal',
		PResult2: 'Difuminar',
		PResult3: 'Limpiar',
		NoData: 'Sin dato',
		ChooseModel: 'Cambiar el modelo del teléfono',
		HadChooseModel: 'Cambiar modelo',
		Cancel: 'Cancelar',
		Confirm: 'Confirmar',
		Restore: 'Restablecer',
		Return: 'Volver',
		Warning: 'Advertencia',
		GongNeng: 'Personalizar',
		TuKu: 'Galería',
		Photo: 'Foto',
		Sticker: 'Sticker',
		Text: 'Texto',
		AddText: 'Añador texto',
		PleaseChoose: 'Por favor selecciona tipo de letra',
		PleaseChooseText: 'Por favor introduce el texto',
		PictureEdit: 'Ajusta la imagen',
		WarningTips: 'Por favor carga la imagen en formato png, jpg o jpeg',
		UploadLimit: '¡ El tamaño de la imagen cargada no puede exceder los 30 MB !',
		ModelTips: '¡ Por favor escoge el modelo primero !',
		PicTips: '¡ Por favor carga la imagen primero !',
		PicChooseTips: 'Por favor selecciona primero la imagen que deseas ajustar',
		PicMake: 'Produciendo',
		PicLoad: 'Subiendo',
		Loading: 'Cargando',
		Reminder: 'Recordatorio',
		UploadTips0: 'Por favor, lea y marque',
		UploadTips1: 'Asegúrese de que las imágenes subidas sean claras y disponibles, y no puede cargar imágenes ilegales e ilegales. Por favor, lea atentamente.',
		UploadTips2_1: 'Protocolo de personalización de patrones de pegatinas móviles',
		UploadTips2_2: 'Protocolo de personalización de patrones de carcasa de teléfono móvil',
		UploadTips3: 'Haga clic en "aceptar" para indicar que ha leído y aceptado el acuerdo.',
		Back: 'Disconforme',
		Know: 'De acuerdo, continuar',
		PhoneProtocol: 'Protocolo de personalización de patrones',
		NoPic: 'No más imágenes !',
		NoSn: 'La información del sistema no existe',
		NoMid: 'La información del material no existe',
		CusDisabled: 'El cliente ha sido deshabilitado',
		NoCustomerInfo: 'La información del cliente no existe',
		SnDisabled: 'Anomalía del dispositivo, póngase en contacto con el servicio de atención al cliente.',
		NoSystem: 'Sin configuración ',
		Loadingg: 'cargando',
		GeneratingOrders: 'Generando ordenes',
		DownloadInfo: 'Por favor saque una captura de pantalla para guardar la imagen o use el navegador para abrir la imagen y guardarla',
		DownloadLoading: 'Guardando imagenes',
		DownloadSuccess: 'La imagen ha sido guardada satisfactoriamente en el album',
		PhotoChange: 'Vuela a seleccionar la imagen debido al cambio de modelo',
		SelectPicture: 'Selecciona la imagen',
		MapTransfer: 'Carga la imagen',
		ClosePicture: 'cerca',
		LoadingResource: 'Cargando recursos',
		NoModelList: "No hay datos del modelo, vuelve a ingresar",
		NoModelLists: "No hay datos del modelo",
		BrandName: "Nombre de la marca",
		JixinName: "Nombre del modelo",
		SearchContent: 'Buscando modelo del telefono móvil',
		PopularModels: 'Modelo de la marca',
		SuccessRestore: 'Recuperación correcta',
		SuccessRestoreRotate: 'El inico del angulo de rotación es correcto',
		FunctionNoOpen: 'Esta función esta en desarrollo',
		Rotate: 'Girar',
		Scale: 'Cambiar tamaño',
		Acoustic: 'Acustico',
		RotateLeft15: '-15',
		RotateRight15: '15',
		ReadAndCheckTheAgreement: 'Por favor lea y acepte el acuerdo primero',
		FlipHorizontal: 'Voltear horizontalmente',
		FlipVertical: 'Voltear verticalmente',
		FlipHorizontalYes: 'Se ha abierto la voltereta horizontal',
		FlipHorizontalNo: 'Se ha cerrado la voltereta horizontal',
		FlipVerticalYes: 'Se ha abierto la voltereta vertical',
		FlipVerticalNo: 'Se ha cerrado la voltereta vertical'
	},
	P: {
		ConfirmPrint: 'Confirmar impresión',
		PrintPreview: 'Vista previa renderizada',
		Model: 'Modelo de teléfono',
		Remark: 'Observar la información como: nº de telefono, dirección de correo electrónico para consultar su pedido',
		SubmitPrint: 'Enviar para imprimir'
	},
	S: {
		SubmitSuccess: 'Enviado con éxito',
		PrintInfo: 'Información de su pedido',
		PrintSceen: 'Por favor saque una captura de pantalla para guardar la imagen y mostrarsela al personal',
		PrintNo: 'Impresión Nº',
		Save: 'Guardar en Fotos',
		CancelOrder: 'Cancelar orden',
		CancelOrderSuccess: 'Pedido cancelado con éxito',
		CancelOrderFail: 'Cancelación del pedido fallida. Por favor contacta con el servicio de atención al cliente.',
		ScreenshotSave: 'Por favor, use una captura de pantalla de su teléfono móvil para guardar la información del pedido.',
		copySuccess: 'Copia exitosa',
		copyError: 'Falló la copia',
		modelTipText: 'La red es anormal, por favor vuelva a generar el pedido',
	}
}
