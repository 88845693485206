module.exports = {
	C: {
		Next: 'Далі',
		Copyright: 'Офіційна версія',
		Tips: 'Підказка: червона пунктирна рамка – це область, де буде надруковано зображення',
		Tips2: 'Схема екрана телефона використовується для демонстрації готового продукту',
		Print: 'Результат друку',
		PResult1: 'Нормальне зображення',
		PResult2: 'Розмите зображення',
		PResult3: 'Чітке зображення',
		NoData: 'Немає даних',
		ChooseModel: 'Виберіть модель телефона',
		HadChooseModel: 'Змінити модель',
		Cancel: 'Скасувати',
		Confirm: 'Підтвердити',
		Restore: 'Відновити',
		Return: 'Назад',
		Warning: 'Попередження',
		GongNeng: 'Клієнт',
		TuKu: 'Галерея',
		Photo: 'Фото',
		Sticker: 'Наліпка',
		Text: 'Текст',
		AddText: 'Додати текст',
		PleaseChoose: 'Виберіть шрифт',
		PleaseChooseText: 'Введіть текст',
		PictureEdit: 'Налаштувати зображення',
		WarningTips: 'Завантажте зображення у форматі png, jpg або jpeg',
		UploadLimit: 'Розмір завантажуваного зображення не може перевищувати 30 МБ!',
		ModelTips: 'Спочатку виберіть модель!',
		PicTips: 'Спочатку завантажте зображення!',
		PicChooseTips: 'Спочатку виберіть зображення, яке потрібно налаштувати',
		PicMake: 'Створення',
		PicLoad: 'Вивантаження роботи',
		Loading: 'Завантаження',
		Reminder: 'Нагадування',
		UploadTips0: 'Please read and check',
		UploadTips1: 'Будь ласка, переконайтеся, що вивантажені зображення є чистими і корисними, і не вивантажуйте нелегальні або нелегальні зображення. Будь ласка, прочитайте уважно',
		UploadTips2_1: 'Договор налаштування шаблонів мобільних телефонів',
		UploadTips2_2: 'Протокол налаштування шаблону регістру телефону',
		UploadTips3: 'Натискання кнопки « погодитися » означає, що ви читали і погодилися з умовленням.',
		Back: 'Не погоджуюсь',
		Know: 'Погодитися та продовжити',
		PhoneProtocol: 'Протокол налаштування шаблонів',
		NoPic: 'Немає більше зображень!',
		NoSn: 'Інформація про пристрій відсутня',
		NoMid: 'Інформація про матеріал відсутня',
		CusDisabled: 'Користувача деактивовано',
		NoCustomerInfo: 'Інформація про користувача відсутня',
		SnDisabled: 'Несправність пристрою. Зверніться в службу підтримки!',
		NoSystem: 'Немає конфігурації',
		Loadingg: 'Завантаження',
		GeneratingOrders: 'Формування замовлень',
		DownloadInfo: 'Зробіть знімок екрана, щоб зберегти зображення, або скористайтеся браузером, щоб відкрити та зберегти зображення',
		DownloadLoading: 'Збереження зображень',
		DownloadSuccess: 'Зображення збережено в альбом',
		PhotoChange: 'Виберіть зображення ще раз через зміну моделі',
		SelectPicture: 'Вибрати зображення',
		MapTransfer: 'Завантажити зображення',
		ClosePicture: 'Закрити',
		LoadingResource: 'Ресурси завантажуються',
		NoModelList: "Інформаця про модель відсутня, введіть дані ще раз",
		NoModelLists: "Інформаця про модель відсутня",
		BrandName: "Бренд",
		JixinName: "Назва моделі",
		SearchContent: 'Пошук моделі телефона',
		PopularModels: 'Брендова модель',
		SuccessRestore: 'Відновлення завершено',
		SuccessRestoreRotate: 'Ініціалізація кута повороту виконана',
		FunctionNoOpen: 'Ця функція перебуває на стадії розробки',
		Rotate: 'Обертати',
		Scale: 'Масштабувати',
		Acoustic: 'Акустичний',
		RotateLeft15: '15 град',
		RotateRight15: '15 град',
		ReadAndCheckTheAgreement: 'Спочатку прочитайте та перевірте угоду',
		FlipHorizontal: 'Горизонтальне обертання',
		FlipVertical: 'Vertical Flip',
		FlipHorizontalYes: 'Увімкнено горизонтальне обертання',
		FlipHorizontalNo: 'Горизонтальне обертання вимкнено',
		FlipVerticalYes: 'Ввімкнено вертикальне перетворення',
		FlipVerticalNo: 'Вертикальне обертання вимкнено'
	},
	P: {
		ConfirmPrint: 'Підтвердити друк',
		PrintPreview: 'Попередній перегляд рендерінгу',
		Model: 'Модель телефона',
		Remark: 'Зазначте номер телефону та адресу електронної пошти для запиту замовлення',
		SubmitPrint: 'Надіслати до друку'
	},
	S: {
		SubmitSuccess: 'Надіслано',
		PrintInfo: 'Інформація про замовлення',
		PrintSceen: 'Зробіть знімок екрана, щоб зберегти його для друку',
		PrintNo: 'Номер друку',
		Save: 'Зберегти у [Фото]',
		CancelOrder: 'Скасувати замовлення',
		CancelOrderSuccess: 'Замовлення анульовано',
		CancelOrderFail: 'L annulation de la commande a échouée. Contacter le service client.',
		ScreenshotSave: 'Будь ласка, зробіть знімок вашого телефону, щоб зберегти інформацію про порядок',
		copySuccess: 'Копіювання успішно ',
		copyError: 'не вдалося копіювати',
		modelTipText: 'Виключення мережі, будь ласка, відновити порядок',
	}
}
