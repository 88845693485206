module.exports = {
	C: {
		Next: '下一步',
		Copyright: '官方',
		Tips: '提示：紅色虛線框內為圖片打印區域；',
		Tips2: '手機線框圖內為成品預覽效果；',
		Print: '印刷效果',
		PResult1: '一般',
		PResult2: '模糊',
		PResult3: '清晰',
		NoData: '暫無數據',
		ChooseModel: '選擇手機型號',
		HadChooseModel: '更換機型',
		Cancel: '取消',
		Confirm: '確定',
		Restore: '復原',
		Return: '返回',
		Warning: '警告',
		GongNeng: '調整',
		TuKu: '圖庫',
		Photo: '傳圖',
		Sticker: '貼紙',
		Text: '文字',
		AddText: '添加文字',
		PleaseChoose: '請選擇字體',
		PleaseChooseText: '請輸入文字內容',
		PictureEdit: '調整圖片',
		WarningTips: '請上傳格式為image/png, image/jpg, image/jpeg的圖片',
		UploadLimit: '上傳圖片大小不能超過 30MB!',
		ModelTips: '請先選擇機型!',
		PicTips: '請先上傳圖片!',
		PicChooseTips: '請先選中要調整的圖片',
		PicMake: '作品製作中',
		PicLoad: '作品上傳中',
		Loading: '載入中',
		Reminder: '溫馨提示',
		UploadTips0: '請閱讀並勾選',
		UploadTips1: '請確保上傳的圖片清晰可用，不可上傳違法、違規圖片。 請仔細閱讀',
		UploadTips2_1: '《手機貼紙圖案定制協議》',
		UploadTips2_2: '《手機殼圖案定制協議》',
		UploadTips3: '，點擊“同意”表示您已閱讀並同意該協定。',
		Back: '不同意',
		Know: '同意並繼續',
		PhoneProtocol: '圖案定制協議',
		NoPic: '沒有更多圖片哦!',
		NoSn: '設備信息不存在',
		NoMid: '材質信息不存在',
		CusDisabled: '客戶已被禁用',
		NoCustomerInfo: '客戶信息不存在',
		SnDisabled: '設備異常，請聯繫客服！',
		NoSystem: '暫無任何配置',
		Loadingg: '載入中',
		GeneratingOrders: '正在生成訂單',
		DownloadInfo: '請截圖保存圖片或使用瀏覽器打開保存圖片',
		DownloadLoading: '保存圖片中',
		DownloadSuccess: '圖片已成功保存到相冊',
		PhotoChange: '由於機型改變，請重新選擇圖片',
		SelectPicture: '選圖',
		MapTransfer: '傳圖',
		ClosePicture: '關閉',
		LoadingResource: '資源正在加載中',
		NoModelList: "無任何機型數據，請重新輸入",
		NoModelLists: "暫無任何型號數據",
		BrandName: "品牌名稱",
		JixinName: "機型名稱",
		SearchContent: '搜索手機型號',
		PopularModels: '品牌機型',
		SuccessRestore: '復原成功',
		SuccessRestoreRotate: '旋轉角度初始化成功',
		FunctionNoOpen: '該功能正在開發中',
		Rotate: '旋轉',
		Scale: '縮放',
		Acoustic: '鏡像',
		RotateLeft15: '逆15度',
		RotateRight15: '順15度',
		ReadAndCheckTheAgreement: '請先閱讀並勾選協議',
		FlipHorizontal: '水准翻轉',
		FlipVertical: '垂直翻轉',
		FlipHorizontalYes: '已開啟水准翻轉',
		FlipHorizontalNo: '已關閉水准翻轉',
		FlipVerticalYes: '已開啟垂直翻轉',
		FlipVerticalNo: '已關閉垂直翻轉'
	},
	P: {
		ConfirmPrint: '確認打印',
		PrintPreview: '效果圖預覽',
		Model: '手機型號',
		Remark: '備註信息，如：手機號、郵箱地址，以便訂單查詢',
		SubmitPrint: '提交打印'
	},
	S: {
		SubmitSuccess: '提交成功啦~',
		PrintInfo: '您的訂單信息',
		PrintSceen: '請截圖保存，並出示給店員打印',
		PrintNo: '打印編號',
		Save: '保存至相册',
		CancelOrder: '訂單作廢',
		CancelOrderSuccess: '訂單作廢成功',
		CancelOrderFail: '訂單作廢失敗，請聯繫客服',
		ScreenshotSave: '請使用手機截圖保存訂單資訊',
		copySuccess: '複製成功',
		copyError: '複製失敗',
		modelTipText: '網絡异常，請重新生成訂單',
	}
}
