module.exports = {
	C: {
		Next: '次のページ',
		Copyright: 'オフィシャル',
		Tips: 'ヒント：赤い点線枠は画像が印刷されるエリアです',
		Tips2: 'スマートフォンのワイヤーフレームは完成品のプレビューです',
		Print: '印刷結果',
		PResult1: 'ノーマル',
		PResult2: 'ぼかし',
		PResult3: ' クリア',
		NoData: ' データなし',
		ChooseModel: '機種を選択',
		HadChooseModel: '機種を変更',
		Cancel: 'キャンセル',
		Confirm: '確認する',
		Restore: '復元する',
		Return: '戻る',
		Warning: '注意事項',
		GongNeng: 'カスタム',
		TuKu: 'ギャラリー',
		Photo: '写真',
		Sticker: 'ステッカー',
		Text: 'テキスト',
		AddText: 'テキストを追加',
		PleaseChoose: 'フォントを選択してください',
		PleaseChooseText: 'テキストの内容を入力してください',
		PictureEdit: '画像を調整する',
		WarningTips: 'png jpg jpeg形式の画像をアップロードしてください',
		UploadLimit: 'アップロードできる画像サイズは30MBまでとなります',
		ModelTips: '最初にモデルを選択してください',
		PicTips: '最初に画像をアップロードしてください',
		PicChooseTips: '最初に調整する画像を選択してください',
		PicMake: '進行中',
		PicLoad: 'アップロード中',
		Loading: '読み込み中',
		Reminder: '重要',
		UploadTips0: '必ずご確認ください',
		UploadTips1: '',
		UploadTips2_1: '『重要事項説明』',
		UploadTips2_2: '『重要事項説明』',
		UploadTips3: 'をご確認いただき（クリックでご確認いただけます）、同意いただける場合のみ「同意して続ける」で続行してください。アップロードする画像について、人物の肖像権、キャラクター等の商標権又は著作権等を侵害していないかは、お客様ご自身でご確認いただく必要があります。',
		Back: '同意しない',
		Know: '同意して続ける',
		PhoneProtocol: '重要事項説明',
		NoPic: '画像がありません',
		NoSn: 'デバイス情報がありません',
		NoMid: '素材情報がありません',
		CusDisabled: '顧客が無効になりました',
		NoCustomerInfo: '顧客情報がありません',
		SnDisabled: '機器に異常があります',
		NoSystem: 'まだ設定されていません',
		Loadingg: '読み込み中',
		GeneratingOrders: '注文の作成',
		DownloadInfo: 'スクリーンショットを撮って画像を保存するか、ブラウザで画像を開いて保存してください',
		DownloadLoading: '画像の保存',
		DownloadSuccess: '画像はアルバムに正常に保存されました',
		PhotoChange: 'モデルチェンジのため、写真を選択し直してください',
		SelectPicture: '写真を選択',
		MapTransfer: '写真をアップロードする',
		ClosePicture: '閉じる',
		LoadingResource: '情報をロード読み込んでいます',
		NoModelList: "モデルデータがありません。再度入力してください。",
		NoModelLists: "モデルデータがありません",
		BrandName: "ブランド名",
		JixinName: "型番名",
		SearchContent: '携帯電話の型番を検索',
		PopularModels: 'ブランド名',
		SuccessRestore: 'リカバリー成功',
		SuccessRestoreRotate: '回転角度の初期化成功',
		FunctionNoOpen: 'この機能は現在開発中です',
		Rotate: '回転',
		Scale: 'ズーム',
		Acoustic: '音響',
		RotateLeft15: '15度',
		RotateRight15: '15度',
		ReadAndCheckTheAgreement: '最初に同意事項をご確認ください',
		FlipHorizontal: '水平反転',
		FlipVertical: '垂直反転',
		FlipHorizontalYes: '水平反転がオン',
		FlipHorizontalNo: '水平反転をオフにしました',
		FlipVerticalYes: '垂直反転がオン',
		FlipVerticalNo: '垂直反転を閉じました'
	},
	P: {
		ConfirmPrint: '印刷確認',
		PrintPreview: 'プレビュー',
		Model: '電話機の型番',
		Remark: '電話番号、Eメールアドレスなど、ご注文に関する備考情報',
		SubmitPrint: '印刷する'
	},
	S: {
		SubmitSuccess: '正常に送信',
		PrintInfo: 'ご注文情報',
		PrintSceen: 'スクリーンショットを撮って保存し、印刷のためにスタッフに見せてください。',
		PrintNo: 'オーダーナンバー',
		Save: '写真を保存',
		CancelOrder: '注文をキャンセル',
		CancelOrderSuccess: '注文は正常に無効化されました',
		CancelOrderFail: '注文のキャンセルに失敗しました。カスタマーサービスにご連絡ください。',
		ScreenshotSave: '注文情報は携帯電話のスクリーンショットで保存してください',
		copySuccess: 'コピー成功',
		copyError: 'コピーに失敗しました',
		modelTipText: 'ネットワーク例外、注文を再生成してください',
	}
}
