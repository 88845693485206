<template>
	<div class="page-container">
		<div class="phone-t" ref="phoneT" id="phone">
			<!-- 手机定制面板 -->
			<div class="phone-wrapper" :style="{ height: wheight + 'px' }">
				<draw-container ref="mcanvas" @submit="submit" @dpi="getdpi" @handleLoading="handleLoading"
					@clear="clearImgOrText" @select="selectImgOrText" @chooseImg="chooseImg"
					:materialsColorValue="materialsColorValue" :spriteArr="spriteArr" :nw="nw" :nh="nh" :mw="mw"
					:mh="mh" :bgimg="phoneImg" :upimg="frameImg" :wheight="wheight" :pwidth="phonenWidth"
					:pheight="phonenHeight" :whiteScopeW="whiteScopeW" :whiteScopeT="whiteScopeT" :frameW="frameW"
					:frameH="frameH" :flipHorizontal="flipHorizontal" :flipVertical="flipVertical">
				</draw-container>
			</div>

			<!-- 印刷效果 -->
			<div class="print-result" v-show="dpiLevel"
				:class="[getDpiValue(dpiValue), { 'print-en': $i18n.locale !== 'zh-cn' }]">
				<span>{{ $t("C.Print") }}</span>
				<span class="result" v-show="dpiLevel === 1">{{ $t("C.PResult1") }}</span>
				<span class="result" v-show="dpiLevel === 2">{{ $t("C.PResult2") }}</span>
				<span class="result" v-show="dpiLevel === 3">{{ $t("C.PResult3") }}</span>
			</div>

			<!-- 多图层/其他操作按钮 -->
			<div class="handle-wrap">
				<div class="handle-list" :class="[{ more1: showMoreHandle }]">
					<span class="sprite-icon icon-handle" v-show="!showMoreHandle"
						@click="showMoreHandle = true"></span>
					<div class="more-handle" v-show="showMoreHandle">
						<van-icon name="arrow" @click="showMoreHandle = false" />
						<div class="handle-item" @click.prevent="chooseImg">
							<span class="sprite-icon icon-picture"></span>
							<input type="file" style="display: none; height: 0; width: 0"
								accept="image/jpeg,image/jpg,image/png" id="picfile" ref="file"
								@change="picUpload($event)" />
							<p>{{ $t("C.Photo") }}</p>
						</div>
						<div class="handle-item" v-if="openSticker">
							<span class="sprite-icon icon-paster"></span>
							<p>{{ $t("C.Sticker") }}</p>
						</div>
						<div class="handle-item" v-if="openWrittenWords" @click="openText">
							<span class="sprite-icon icon-text"></span>
							<p>{{ $t("C.Text") }}</p>
						</div>
					</div>
				</div>
			</div>

			<!-- 型号选择 -->
			<ul class="model-wrap" :style="'bottom:' + phoneBottom + 'px;'">
				<li @click="handleModel">
					<div v-if="modelName">
						<p>{{ $t("C.HadChooseModel") }} : {{ modelName }}</p>
						<van-icon name="arrow" />
					</div>
					<p v-else>{{ $t("C.ChooseModel") }}</p>
				</li>
			</ul>
		</div>

		<!-- 机型 -->
		<transition name="fadeModel">
			<div class="model-wrapper aaaaa" v-show="showModel">
				<Model class="model" :style="'height:' + curModelHeight + (showModel? '' : 'px')" :type="'bottom'"
					:brandId="brandId" :modelId="modelId" :brandList="brandList" :modelList="modelList"
					:topTenModelList="topTenModelList" @change="changeBrand" @changeModel="switchModel" @cancel="handleCancelModel"
					@confirm="handleConfirmModel" @setModelTop="setModelTop" @setModelCenter="setModelCenter"
					@setModelBottom="setModelBottom">
				</Model>
			</div>
		</transition>

		<!-- 底部tab栏 -->
		<div class="nav-wrapper">
			<div class="nav-list">
				<div class="nav-item" @click="openPic">
					<span class="icon icon-picture"></span>
					<div class="text">{{ $t("C.TuKu") }}</div>
				</div>
				<div class="nav-item" @click.prevent="chooseImg">
					<input type="file" style="display: none; height: 0; width: 0"
						accept="image/jpeg,image/jpg,image/png" id="picfile" ref="file" @change="picUpload($event)" />
					<span class="icon icon-camera"></span>
					<div class="text">{{ $t("C.Photo") }}</div>
				</div>
				<div class="nav-item" @click="openText">
					<span class="icon icon-edit2"></span>
					<div class="text">{{ $t("C.Text") }}</div>
				</div>
				<div class="nav-item" @click="openCustomDialog">
					<span class="icon icon-custom"></span>
					<div class="text">{{ $t("C.GongNeng") }}</div>
				</div>
			</div>
			<div class="btn-submit" @click="handleSubmit">
				<div class="text">
					{{ $t("C.Next") }}
				</div>
			</div>
		</div>

		<!-- 图库 -->
		<div class="picture-wrapper" :class="{ shadow: showPicture, hidden: !showPicture }"
			:style="'height:' + curHeight + (showPicture ? '' : 'px')" v-swipeup="{ fn: swipeup }">
			<div class="handle-pic" @click="openPic" v-swipedown="{ fn: swipedown }">
				<span class="line line-arrow-up" v-show="!showPicture"></span>
				<span class="line line-arrow-down-close" v-show="showPicture">{{ $t("C.ClosePicture") }}</span>
				<span class="photoSelectA" v-show="showPicture">{{ $t("C.SelectPicture") }}</span>
				<span class="photoSelectB" @click.prevent="chooseImg"
					v-show="showPicture">{{ $t("C.MapTransfer") }}</span>
			</div>
			<div class="picture-data">
				<Picture ref="pictureList" :curCategory="curCategory" :picCategory="picCategory"
					@changeSelect="changeSelect" v-show="picCategory && picCategory.length > 0"></Picture>
				<div class="no-data-wrap" v-show="picCategory && picCategory.length <= 0">
					<NoData :showBtn="false"></NoData>
				</div>
			</div>
		</div>

		<!-- 蒙版 -->
		<div class="mask" v-show="showMask"></div>

		<!-- 提示 -->
		<Loading v-show="isLoading" :message="message"></Loading>

		<!-- 相册上传图片提示框 -->
		<div class="flexible-dialog" v-show="showProtocol">
			<div class="flexible-dialog-content">
				<div class="flexible-cotent-middle-title" v-if="bLang == 'jap'" style="color: red;">
					{{ $t("C.Reminder") }}
				</div>
				<div class="flexible-cotent-middle-title" v-else>
					{{ $t("C.Reminder") }}
				</div>
				<div class="flexible-cotent-middle-info">
					<div>
						{{ $t("C.UploadTips1") }}<span style="color: #29c4da;"
							@click="lookAgreement">{{ $t("C.UploadTips2_1") }}</span>{{ $t("C.UploadTips3") }}
					</div>
				</div>
				<div class="flexible-cotent-bottom">
					<div class="flexible-cotent-bottom-left" @click="cancelPhotoModel">{{ $t("C.Back") }}</div>
					<div class="flexible-cotent-bottom-right" @click="confirmPhotoModel">{{ $t("C.Know") }}</div>
				</div>
			</div>
		</div>

		<!-- 特殊 - 协议弹框A -->
		<div class="flexible-dialog" v-show="showProtocolA">
			<div class="flexible-dialog-content">
				<span @click="closeProtocolA"
					style="position: absolute;right: 10px;top: 5px;font-size: 32px;color: #a0a0a0">×</span>
				<div class="flexible-cotent-middle-title">
					ATTENZIONE
				</div>
				<div class="flexible-cotent-middle-info">
					<div>
						prima di aderire al servizio e di procedere alla realizzazione della Tua cover personalizzata,
						Ti raccomandiamo di leggere attentamente i <span style="font-weight: bold;">Termini d’uso del
							servizio, le Regole per la
							personalizzazione delle Cover e Copyright Policy e l’Informativa Privacy</span> e Ti
						chiediamo di
						confermarci la Tua accettazione. In caso di dubbi, non esitare a rivolgerti al nostro staff per
						chiarimenti.
					</div>
				</div>
				<div class="flexible-cotent-bottom" style="background-color: #d8f2f2;border-radius: 12px;">
					<div style="color: #000000;" @click="confirmProtocolA">PROSEGUI</div>
				</div>
			</div>
		</div>

		<!-- 特殊 - 协议弹框B -->
		<div class="flexible-dialog" v-show="showProtocolB">
			<div class="flexible-dialog-content">
				<span @click="closeProtocolB"
					style="position: absolute;right: 10px;top: 5px;font-size: 32px;color: #a0a0a0">×</span>
				<div class="flexible-cotent-middle-title">
					MODULO DI ADESIONE AL SERVIZIO
				</div>
				<div class="flexible-cotent-middle-info">
					<div style="height: 300px;overflow-y: scroll;">
						Confermo la mia adesione al servizio di personalizzazione delle cover e l’acquisto della cover
						da me realizzata, dichiarando di avere letto e compreso e di accettare integralmente:
						<div v-for="(item, index) in homeRuleProtocolList" :key="index"
							style="font-size: 14px;display: flex;align-items: center;justify-content: flex-start;margin: 8px;margin-top: 10px;">
							<div style="width: 10%;">
								<input type="checkbox" v-model="item.isChecked" :disabled="!item.isRead">
							</div>
							<div style="margin-left: 10px;text-decoration: underline;width: 85%;word-break: break-word;"
								@click="lookAgreement2(item)">
								{{item.protocolName}}
							</div>
						</div>
						In caso di personalizzazione della cover con immagini e/o testi da me forniti, garantisco che
						l’uso di tali immagini e/o testi è stato autorizzato dai soggetti eventualmente riprodotti o
						citati (o, in caso di minori, i soggetti esercenti la potestà genitoriale) e non viola la legge
						o i diritti di terzi e che le immagini e/o i testi:
						<br>
						<div>
							<ul>
								<li><span style="font-weight: bold;"> · </span>non hanno contenuto illecito,
									diffamatorio, offensivo, discriminatorio,
									violento, di
									incitamento all’odio, volgare, osceno o comunque irrispettoso</li>
								<li><span style="font-weight: bold;"> · </span>sono stati da me realizzati o, in ogni
									caso, sono titolare dei relativi diritti di
									riproduzione</li>
								<li><span style="font-weight: bold;"> · </span>non contengono marchi, loghi, nomi a
									dominio, simboli, slogan, motti, citazioni o
									segni
									distintivi sui quali soggetti terzi vantino diritti o che siano con essi
									confondibili
								</li>
							</ul>
						</div>
						<br>
						<span>
							Mi impegno a rispettare le Regole per la Personalizzazione e la Copyright Policy e a
							manlevare THC Italia S.r.l. da ogni responsabilità o danno derivanti da mie violazioni di
							tali Regole o Copyright Policy, dall’uso di immagini e/o testi da me forniti e, in generale,
							da pretese violazioni delle norme applicabili o di diritti di terzi derivanti dalla
							realizzazione della cover personalizzata da me acquistata.
						</span>
						<span>
							Autorizzo il trattamento dei miei dati personali, comprese le immagini e/o i testi da me
							forniti, per quanto necessario per la realizzazione e la fornitura della cover e
							l’adempimento degli obblighi di legge.
						</span>
					</div>
				</div>
				<div class="flexible-cotent-bottom" style="background-color: #d8f2f2;border-radius: 12px;">
					<div style="color: #000000;" @click="confirmProtocolB">CONFERMO E ADERISCO</div>
				</div>
			</div>
		</div>

		<!-- 协议内容 -->
		<div class="flexible-dialog" v-show="showSureProtocol">
			<div class="flexible-dialog-content" style="width: 100%;height: 100%;border-radius: 0;">
				<div class="flexible-cotent-middle-info" style="width: 100%;height: 100%;">
					<div style="height: 100%;width: 100%;overflow: hidden;">
						<iframe :src="agreementContent" frameborder="0" height="100%" width="100%">
						</iframe>
					</div>
				</div>

				<div class="flexible-cotent-bottom" style="background-color: #d8f2f2;border-radius: 12px 12px 0 0;">
					<div style="color: #000000;" @click="readProtocol(protocolId)">avevo letto<span
							v-if="count > 0">({{ count }}s)</span></div>
				</div>
			</div>
		</div>

		<!-- 添加文字 -->
		<transition name="fadeText">
			<div class="text-wrapper" id="textWrapper" ref="textWrapper" v-show="textDialog">
				<div class="title">
					<span class="line"></span>
					<div class="text">{{ $t("C.AddText") }}</div>
					<i class="icon icon-close" @click="closeTextDialog"></i>
				</div>
				<van-field class="input" v-model="inputtext" :placeholder="$t('C.PleaseChooseText')" @focus="focusInput"
					clearable>
				</van-field>
				<div class="select-box">
					<van-field class="select" :value="getFamilyName(family)" :placeholder="$t('C.PleaseChoose')"
						right-icon="arrow-down" @click="toggle" readonly>
					</van-field>
					<div class="select-list" v-show="showSelct">
						<div class="select-item" v-for="item in fontFamily" :key="item.id" @click="handleSelect(item)">
							{{ $i18n.locale === "zh-cn" ? item.name : item.englishName }}
						</div>
					</div>
				</div>
				<div class="color-box">
					<div class="color" :style="{ backgroundColor: fontColor }"></div>
					<slider-picker v-model="colors" :swatches="swatches"></slider-picker>
				</div>
				<div class="btn-box">
					<div class="btn cancel-btn" @click="closeTextDialog">{{ $t("C.Cancel") }}</div>
					<div class="btn confirm-btn" @click="addText">{{ $t("C.Confirm") }}</div>
				</div>
			</div>
		</transition>

		<!-- 更多功能 -->
		<transition name="fadeCustom">
			<div class="custom-wrapper" v-show="customDialog">
				<div class="title">
					<span class="line"></span>
					<div class="text">{{ pictureEditTitle }}</div>
					<i class="icon icon-close" @click="closeCustomDialog"></i>
				</div>

				<!-- 调整区域 -->
				<div class="custom-wrapper-box" v-show="showAllCustom">
					<div class="box-rotate" @click="clickRestoreIcon">
						<img class="box-rotate-img" src="../../common/images/restore.png" mode="widthFix">
						<div class="box-rotate-title">
							{{ $t("C.Restore") }}
						</div>
					</div>
					<div class="box-rotate" @click="clickRotateIcon">
						<img class="box-rotate-img" src="../../common/images/rotates.png" mode="widthFix">
						<div class="box-rotate-title">
							{{ $t("C.Rotate") }}
						</div>
					</div>
					<div class="box-rotate" @click="clickRotateIconNum('left',15)">
						<img class="box-rotate-img" src="../../common/images/rotateLeft.png" mode="widthFix">
						<div class="box-rotate-title">
							{{ $t("C.RotateLeft15") }}
						</div>
					</div>
					<div class="box-rotate" @click="clickRotateIconNum('right',15)">
						<img class="box-rotate-img" src="../../common/images/rotateRight.png" mode="widthFix">
						<div class="box-rotate-title">
							{{ $t("C.RotateRight15") }}
						</div>
					</div>

				</div>
				<div class="custom-wrapper-box" v-show="showAllCustom">
					<div class="box-rotate" @click="clickFlipIconNum(0)">
						<img class="box-rotate-img" :src="flipHorizontalImage" mode="widthFix">
						<div class="box-rotate-title">
							{{ $t("C.FlipHorizontal") }}
						</div>
					</div>
					<div class="box-rotate" @click="clickFlipIconNum(1)">
						<img class="box-rotate-img" :src="flipVerticalImage" mode="widthFix">
						<div class="box-rotate-title">
							{{ $t("C.FlipVertical") }}
						</div>
					</div>
				</div>

				<div class="slider-box" v-show="showRotateSlider">
					<div class="slider-box-rotate">
						<div class="rotate-slider">
							<vue-slider ref="rotateSlider" v-model="rotateValue" height="8px" railStyle="color: #00bed6"
								:min="rotateMin" :max="rotateMax" :marks="rotateMarks" :disabled="rotateDisable"
								@change="rotateSliderChange">
							</vue-slider>
						</div>
						<div class="rotate-slider-return">
							<div class="btn restore-btn" @click="restoreRotateSlider">{{ $t("C.Restore") }}</div>
							<div class="btn cancel-btn" @click="closeRotateSlider">{{ $t("C.Return") }}</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script type="text/ecmascript-6">
	import TpScroll from '@/utils/tp-scroll.js'
	import DrawContainer from "components/diyCanvas/diyCanvas";
	import Picture from "components/picture/picture";
	import Model from "components/model/model";
	import NoData from "components/noData/noData";
	import Loading from "components/loading/loading";
	import VueSlider from 'vue-slider-component';
	import 'vue-slider-component/theme/default.css'
	import {
		Slider
	} from "vue-color";
	import {
		mapGetters,
		mapMutations
	} from "vuex";
	import "common/js/vue-touch.js";
	import EXIF from "exif-js";
	import api from "api/allApi";
	import OSS from 'ali-oss';
	// import axios from 'axios';
	// import heic2any from 'heic2any';
	import {
		Toast
	} from 'vant'
	export default {
		data() {
			return {
				bLang: '',
				phoneBottom: '',

				localDevSn: "", // 设备sn
				localMid: "", // 材质编码

				// Loading提示
				isLoading: false,
				message: "",

				// 底图数据
				phoneImg: "", // 底图
				mw: 0, // 底图宽
				mh: 0, // 底图长
				nw: 0, // 底图宽
				nh: 0, // 底图长
				pscale: 0, // 底图宽 / 底图长 

				// 画布数据
				wheight: 0, // 屏幕画布可操作长
				phonenWidth: 0, // 缩放后的底图宽
				phonenHeight: 0, // 缩放后的底图长

				// 机型数据
				frameImg: "", // 手机机型框图
				frameW: 0, // 机型宽
				frameH: 0, // 机型高
				leftDistance: 0, // 机型框图往左边偏移的距离（固定值）
				topDistance: 0, // 机型框图往上边偏移的距离（固定值）
				whiteScopeW: 0, // 左移
				whiteScopeT: 0, // 上移
				materialsColorValue: "#FFFFFF", // 底色图颜色（默认白底）

				// 定制图数据
				spriteArr: [], // 添加的数组
				wallpImg: "", // 定制图
				originWallpImg: "", // 由于当选择文字的时候，图片字段为空，使用一个专门保存图片的字段
				imgData: { //初始化图片数据
					posX: null,
					posY: null,
					posuX: null,
					posuY: null,
					sizeW: 20,
					sizeH: 20,
					initW: 20,
					initH: 20,
					rotate: 0
				},

				// 印刷效果
				dpiLevel: 0, // dpi等级
				dpiValue: "", // 效果提示
				ratio: 1, // 缩放比

				// 图库数据
				picCategory: [], // 图库分类
				curCategory: 0, // 当前图库分类
				showPicture: false, // 图库（上滑下滑）
				curHeight: 0, // 当前图库高度
				isFlag: 0, // 相处上传避免不能上传重复

				// 品牌机型数据
				showModel: true, // 品牌机型弹窗
				showMask: true, // 蒙版
				curModelHeight: "55%", // 当前型号高度

				brandList: [], // 品牌列表
				brandId: 0, // 品牌ID
				brandName: "", // 品牌名称

				modelList: [], // 型号列表
				modelId: 0, // 型号ID
				modelName: "", // 型号名称

				topTenModelList: [], //机型TOP10数据

				// 更多操作组件
				showMoreHandle: false, // 是否显示更多操作
				openWrittenWords: true, // 是否显示文字
				openSticker: false, // 是否显示贴纸

				// 文字功能
				textData: { //文字数据
					posX: null,
					posY: null,
					posuX: null,
					posuY: null,
					sizeW: 20,
					sizeH: 20,
					rotate: 0
				},
				textDialog: false, // 文字弹框
				showSelct: false, // 展示选择的字体
				inputtext: "", // 添加文字
				fontFamily: [], // 字体列表
				family: "", // 当前字体
				fontColor: "", // 字体颜色
				colors: {
					hex: "#194d33",
					hsl: {
						h: 150,
						s: 0.5,
						l: 0.2,
						a: 1,
					},
					hsv: {
						h: 150,
						s: 0.66,
						v: 0.3,
						a: 1,
					},
					rgba: {
						r: 25,
						g: 77,
						b: 51,
						a: 1,
					},
					a: 1,
				},
				swatches: ["100", ".80", ".65", ".50", ".35", ".20", "0"], // 颜色板

				// 自定义其他功能
				customDialog: false,
				pictureEditTitle: this.$t("C.PictureEdit"),

				//当打开自定义框的时候默认展示所有按钮
				showAllCustom: true,

				// 旋转
				showRotateSlider: false, // 是否展示旋转
				rotateDisable: false, // 是否禁用组件
				rotateValue: 0, // 图片默认旋转角度
				rotateMarks: [-45, -30, -15, 0, 15, 30, 45], // 可旋转的角度
				rotateMin: -45, // 可旋转的角度最小值
				rotateMax: 45, // 可旋转的角度最大值

				// 翻转
				flipHorizontal: false, // 是否开启水平翻转
				flipVertical: false, // 是否开启垂直翻转
				flipHorizontalImage: 'https://print-oss.rockb2b.com/a_big_h5_resource/icon/flipHorizontal-no.png',
				flipVerticalImage: 'https://print-oss.rockb2b.com/a_big_h5_resource/icon/flipVertical-no.png',

				onLine: navigator.onLine,

				showProtocol: false,
				showProtocolA: false,
				showProtocolB: false,

				homeRuleProtocolList: [],

				protocolId: 0,
				protocolName: '',
				agreementContent: '',
				showSureProtocol: false,
				count: 5 // 设置初始值为5秒
			};
		},
		components: {
			DrawContainer,
			Picture,
			Model,
			NoData,
			Loading,
			"slider-picker": Slider,
			VueSlider
		},
		created() {
			this.message = this.$t("C.Loading");
			this.isLoading = true;
			// 允许页面滚动
			TpScroll.allowScroll();
		},
		mounted() {
			// 监听网络
			window.addEventListener('online', this.updateOnlineStatus)
			window.addEventListener('offline', this.updateOnlineStatus)

			// 判断语言
			this.bLang = window.localStorage.getItem("bLang");

			// 判断协议
			this.homeRuleProtocolList = JSON.parse(localStorage.getItem("homeRuleProtocolList"));
			this.homeRuleProtocolList.forEach(item => {
				item.isChecked = false;
				item.isRead = false;
			});

			// 高度适配
			var height = document.documentElement.clientHeight;
			if (height < 600) {
				this.phoneBottom = 60;
			}

			this.localDevSn = localStorage.getItem("devSn");
			this.localMid = localStorage.getItem("mid");

			// 画布宽高
			this.getDrawSize();

			// 获取配置信息
			this.getInfo();

			// 获取品牌机型
			this.getBrandList();
		},
		beforeDestroy() {
			window.removeEventListener('online', this.updateOnlineStatus);
			window.removeEventListener('offline', this.updateOnlineStatus);
		},
		computed: {
			...mapGetters(["devSn", "mid"]),
		},
		methods: {
			// 关闭弹窗
			closeProtocolA() {
				this.showProtocolA = false;
			},
			closeProtocolB() {
				this.showProtocolB = false;
				this.homeRuleProtocolList.forEach(item => {
					item.isChecked = false;
					item.isRead = false;
				});
			},

			// 多个协议点击相册后的第一个弹框
			confirmProtocolA() {
				this.showProtocolA = false;
				this.showProtocolB = true;

				// 允许页面滚动
				TpScroll.allowScroll();
			},

			// 多个协议点击相册后的第二个弹框
			confirmProtocolB() {
				var flag = 1;
				this.homeRuleProtocolList.forEach(item => {
					if (item.isChecked == false) {
						flag = 0;
					}
				});
				if (flag == 0) {
					Toast({
						message: "Si prega di leggere e controllare prima l'accordo",
						type: 'error',
						duration: 3 * 1000
					});
				} else {
					this.showProtocolB = false;
					this.showMoreHandle = false;
					this.isFlag++;
					this.$refs.file.dispatchEvent(new MouseEvent("click"));

					// 禁止页面滚动
					TpScroll.disableScroll();
				}
			},

			// 查看并阅读协议
			lookAgreement2(item) {
				// 拉起协议弹框
				this.protocolId = item.id;
				this.protocolName = item.protocolName;
				this.agreementContent = item.protocol
				this.showSureProtocol = true;

				// 开启倒计时
				this.startCountdown();
			},

			// 倒计时
			startCountdown() {
				this.count = 5;
				this.timer = setInterval(() => {
					if (this.count > 0) {
						this.count--;
					} else {
						clearInterval(this.timer);
					}
				}, 1000);
			},

			// 阅读协议
			readProtocol(protocolId) {
				if (this.count <= 0) {
					clearInterval(this.timer);
					this.showSureProtocol = false;
					this.homeRuleProtocolList.forEach(item => {
						if (protocolId == item.id) {
							item.isChecked = true;
							item.isRead = true;
						}
					});
				}
			},

			// 监听网络
			updateOnlineStatus(e) {
				const {
					type
				} = e;
				this.onLine = type === 'online';
				if (!this.onLine) {
					Toast({
						message: "Network abnormality, please check the network",
						type: 'error',
						duration: 3 * 1000
					});
				}
			},

			// 画布宽高
			getDrawSize() {
				this.wheight = 450;
			},

			// 获取配置信息
			getInfo() {
				//console.log("页面初始化，获取配置信息");
				// 底图
				this.phoneImg = "https://print-oss.rockb2b.com/print-h5/print-bg-100180.png";
				// 底图宽
				this.mw = this.nw = 101.6;
				// 底图长
				this.mh = this.nh = 177.8;

				// console.log("底图长px: " + this.pm2px(this.mh));
				// console.log("底图宽px: " + this.pm2px(this.mw));
				this.pscale = this.pm2px(this.mw) / this.pm2px(this.mh);

				this.phonenHeight = this.wheight * 0.8; // 360
				this.phonenWidth = this.phonenHeight * this.pscale; // 205.71
				// console.log("缩放后的底图长px: " + this.phonenHeight);
				// console.log("缩放后的底图宽px: " + this.phonenWidth);

				// 机型框图往左边偏移的距离（固定值） 
				// this.leftDistance = 8; // 23
				this.leftDistance = 9;
				// 机型框图往上边偏移的距离（固定值）
				// this.topDistance = 2.5; // 7
				this.topDistance = 2.8;
			},

			// 图库各种弹框方法
			setFalse() {
				this.showMoreHandle = false;
				this.showPicture = false;
				this.showMask = false;
				this.curHeight = 0;
			},
			setTrue() {
				this.showMoreHandle = false;
				this.showPicture = true;
				this.showMask = true;
				this.curHeight = "100%";
			},

			// 型号弹框上拉
			setModelTop() {
				this.showModel = true;
				this.showMask = true;
				this.curModelHeight = "100%";
			},
			setModelCenter() {
				this.showModel = true;
				this.showMask = true;
				this.curModelHeight = "55%";
			},
			setModelBottom() {
				this.showModel = false;
				this.showMask = false;
				this.curModelHeight = 0;
			},

			// ------------------------------------------------- 图库图片 START
			// 根据机型ID获取图库图片
			getPicList() {
				let devSn = this.devSn || this.localDevSn;
				let modelId = this.modelId;
				this.$api.get(this, api.listGallery, {
					deviceSn: devSn
				}).then((res) => {
					if (res && res.code == 0) {
						let picCategory = res.data;
						picCategory.forEach(item => {
							item.deviceSn = devSn;
							item.modelId = modelId;
						});
						this.picCategory = picCategory;
					} else {
						this.picCategory = [];
					}
				});
			},

			// 点击小按钮打开图库
			openPic() {
				this.showPicture = !this.showPicture;
				if (this.showPicture) {
					// 允许页面滚动
					TpScroll.allowScroll();
					this.showMask = true;
					this.curHeight = "70%";
				} else {
					// 禁止页面滚动
					TpScroll.disableScroll();
					this.setFalse();
				}
			},

			// 选择图片
			changeSelect(val1, val2, val3, val4) {
				this.message = this.$t("C.Loading");
				this.isLoading = true;
				this.setFalse();
				this.imgData.posX = null;
				this.picId = val2; // 图片id
				this.changeImage(val1, val2, val3, val4);
			},

			// 改变图片 item-属性 url-图片路径 id-图片id type-类型 picType-图片类型
			changeImage(url, id, type, picType) {
				// 禁止页面滚动
				TpScroll.disableScroll();
				//初始化旋转滑块值和启用滑块
				this.rotateValue = 0;
				this.rotateDisable = false;
				this.wallpImg = url;
				this.originWallpImg = url;
				this.picId = id;
				this.setPicId(this.picId);

				Promise.all([this.loadImage(url, type, picType)]).then((img) => {
					let w = img[0].width;
					let h = img[0].height;
					let scale = this.getScale(w, h); // 图片缩放
					let centerX, centeruX;
					let centerY, centeruY;
					this.imgData.initW = w / scale;
					this.imgData.initH = h / scale;
					if (this.imgData.posX === null) {
						// 画布中心点
						centerX = window.screen.width / 2;
						centerY = this.wheight / 2;
						this.imgData.sizeW = w / scale;
						this.imgData.sizeH = h / scale;
						this.imgData.posX = centerX - w / scale / 2;
						this.imgData.posY = centerY - h / scale / 2;
						// 手机中心点
						centeruX = this.phonenWidth / 2;
						centeruY = this.phonenHeight / 2;
						this.imgData.posuX = centeruX - w / scale / 2;
						this.imgData.posuY = centeruY - h / scale / 2;
						this.imgData.rotate = 0;
						this.imgData.linew = 0;
						this.imgData.lineh = 0;
					} else {
						centerX = this.imgData.posX + this.imgData.sizeW / 2;
						centerY = this.imgData.posY + this.imgData.sizeH / 2;
						centeruX = this.imgData.posuX + this.imgData.sizeW / 2;
						centeruY = this.imgData.posuY + this.imgData.sizeH / 2;
						this.imgData.sizeW = w / scale / this.ratio;
						this.imgData.sizeH = h / scale / this.ratio;
						this.imgData.posX = centerX - this.imgData.sizeW / 2;
						this.imgData.posY = centerY - this.imgData.sizeH / 2;
						this.imgData.posuX = centeruX - this.imgData.sizeW / 2;
						this.imgData.posuY = centeruY - this.imgData.sizeH / 2;
						this.imgData.linew = 0;
						this.imgData.lineh = 0;
					}

					// 选完后图片后初始化图片数据
					let imgObj = {
						url: img[0],
						x: this.imgData.posX,
						y: this.imgData.posY,
						ux: this.imgData.posuX,
						uy: this.imgData.posuY,
						width: this.imgData.sizeW,
						height: this.imgData.sizeH,
						initW: this.imgData.initW,
						initH: this.imgData.initH,
						minW: this.imgData.sizeW,
						minH: this.imgData.sizeH,
						rotate: this.imgData.rotate,
						type: type, // 0 - 图片，1 - 文字
						picType: picType, // 0 - 普通图片，1 - 官方图片
						picId: this.picId ? this.picId : 0, //0 - 普通图 ，数字 - 官方图
						selected: true, // 是否选中
						index: null,
						scale: scale // 缩放
					};

					this.spriteArr = [];
					this.spriteArr.push(imgObj);
					this.isLoading = false;
				});
			},

			// 加载图片
			loadImage(url, type, picType) {
				let img = new Image();
				img.crossOrigin = "anonymous";
				if ((url && url.indexOf("data:") === 0) || Number(picType) === 0) {
					img.src = url;
				} else {
					img.src = url + "?t=" + new Date().getTime();
				}
				return new Promise((resolve) => {
					if (img.complete) {
						resolve(img);
					}
					img.onload = () => resolve(img);
				});
			},

			// 获取缩放
			getScale(width, height) {
				return height / this.phonenHeight > width / this.phonenWidth ?
					width / this.phonenWidth :
					height / this.phonenHeight;
			},

			// const formData = new FormData();
			// formData.append("file", file);
			// axios.post(api.uploadImg, formData, {
			// 	headers: {
			// 		'Content-Type': 'multipart/form-data'
			// 	}
			// }).then(function(res) {
			// 	console.log(res.data.data);
			// });

			// 相机相册上传图片
			picUpload(event) {
				var that = this;
				let file = event.target.files[0];
				let reader = new FileReader();
				reader.readAsArrayBuffer(file);
				reader.onload = function(e) {
					var ints = new Uint8Array(e.target.result);
					ints = ints.slice(0, 500);
					let snippets = new TextDecoder('utf8').decode(ints);
					if (snippets.includes("heic")) {
						that.$toast.fail({
							title: that.$t("C.Warning"),
							message: that.$t("C.WarningTips"),
						});
					} else {
						//that.uploadNewImg(file);
						that.getUploadImg(file);
					}
				}
			},

			//上传图片
			uploadNewImg(file) {
				this.message = this.$t("C.Loading");
				this.isLoading = true;

				if (!(file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg")) {
					this.isLoading = false;
					this.$toast.fail({
						title: this.$t("C.Warning"),
						message: this.$t("C.WarningTips"),
					});
				}

				let isLt30M = file.size / 1024 / 1024 < 30;
				if (!isLt30M) {
					this.isLoading = false;
					this.$toast.fail(this.$t("C.UploadLimit"));
					return;
				}

				// 随机命名
				let random_name =
					this.random_string(6) +
					"_" +
					new Date().getTime() +
					"." +
					file.name.split(".").pop();
				const client = new OSS({
					region: "oss-cn-shenzhen",
					// accessKeyId: "LTAI4Ff77WX8MVBroUS1Gecf",
					// accessKeySecret: "W3uFbWIeQNjnRePGBS1NxwPubZr0mx",
					accessKeyId: "LTAI5tNCakDcr4TcB7PGtEu3",
					accessKeySecret: "QQW0NptDxVrs2igcEs4ATRrF2IQFRF",
					bucket: "rock-printer",
					endpoint: "https://oss-accelerate.aliyuncs.com",
					secure: true,
				});
				// 上传
				client
					.multipartUpload("print/" + random_name, file, {})
					.then((results) => {
						return new Promise((resolve) => {
							var imgUrl = "https://print-oss.rockb2b.com/" + results.name;
							this.wallpImg = imgUrl;
							this.changeImage(this.wallpImg, 0, 0, 0);
							resolve(true);
						});
					})
					.catch((err) => {
						console.log(err);
					});
			},

			//随机生成文件名
			random_string(len) {
				len = len || 32;
				var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
				var maxPos = chars.length;
				var pwd = "";
				for (let i = 0; i < len; i++) {
					pwd += chars.charAt(Math.floor(Math.random() * maxPos));
				}
				return pwd;
			},

			// 相机相册上传图片
			getUploadImg(file) {
				this.message = this.$t("C.Loading");
				this.isLoading = true;
				let reader = this.handleBeforeUpload(file);
				reader.addEventListener("load", () => {
					Promise.all([this.loadImage(reader.result)]).then((img) => {
						let pic = this.handleImage(img[0]);
						this.wallpImg = pic;
						this.imgData.posX = null;
						this.changeImage(this.wallpImg, 0, 0, 0);
					});
				});
			},

			// 处理相册图片
			handleImage(imgObj) {
				let img;
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");

				EXIF.getData(imgObj, function() {
					canvas.width = imgObj.width;
					canvas.height = imgObj.height;

					ctx.drawImage(imgObj, 0, 0, imgObj.width, imgObj.height);
					img = canvas.toDataURL("image/png");
				});
				return img;
			},

			// 相机相册上传图片 - 上传图片验证
			handleBeforeUpload(file) {
				let reader = new FileReader();
				if (!(file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg")) {
					this.isLoading = false;
					this.$toast.fail({
						title: this.$t("C.Warning"),
						message: this.$t("C.WarningTips"),
					});
					return;
				} else {
					reader.readAsDataURL(file);
				}
				let isLt30M = file.size / 1024 / 1024 < 30;
				if (!isLt30M) {
					this.isLoading = false;
					this.$toast.fail(this.$t("C.UploadLimit"));
					return;
				}
				return reader;
			},

			// 相机相册上传图片 - 上传图片提示
			chooseImg() {
				this.showMoreHandle = false;
				if (this.isFlag > 0) {
					this.$refs.file.value = ""; // 再次点击清空input="file"数据，以免同一图片不能重复上传
					this.$refs.file.dispatchEvent(new MouseEvent("click"));
				} else {
					if (this.homeRuleProtocolList.length <= 1) {
						this.showProtocol = true; // open
						this.showProtocolA = false;
						this.showProtocolB = false;
					} else {
						this.showProtocol = false;
						this.showProtocolA = true; // open
						this.showProtocolB = false;
					}
				}
			},

			// 相册上传点击不同意
			cancelPhotoModel() {
				this.showProtocol = false;
				this.showMoreHandle = false;
			},

			// 相册上传点击同意
			confirmPhotoModel() {
				this.showProtocol = false;
				this.showMoreHandle = false;
				this.isFlag++;
				this.$refs.file.dispatchEvent(new MouseEvent("click"));
			},

			// 查看定制协议
			lookAgreement() {
				let mid = 1;
				let bLang = window.localStorage.getItem("bLang");
				this.$router.push({
					path: "/customerProtocol",
					query: {
						mid: mid,
						bLang: bLang,
						protocol: localStorage.getItem("protocol")
					}
				});
			},

			// 获取dpi
			getdpi(value, ratio) {
				this.dpiValue = value;
				this.ratio = ratio;
			},

			// 获取dpi后回显状态
			getDpiValue(value) {
				this.dpiValue = value;
				let dpiRate = Number(value) / 50 >= 5 ? 5 : Math.ceil(Number(value) / 50);
				if (dpiRate >= 5) {
					this.dpiLevel = 3;
					return "cur-print";
				} else if (dpiRate > 2 && dpiRate < 5) {
					this.dpiLevel = 2;
					return "cur-print";
				} else if (dpiRate > 0 && dpiRate <= 2) {
					this.dpiLevel = 1;
					return "cur-print";
				} else {
					this.dpiLevel = "";
					return "cur-print";
				}
			},

			// 图库上滑
			swipeup() {
				this.setTrue();
			},

			// 图库下滑
			swipedown() {
				this.setFalse();
			},
			// ------------------------------------------------- 图库图片 END

			// ------------------------------------------------- 品牌机型 START
			// 获取品牌
			getBrandList() {
				let devSn = this.devSn || this.localDevSn;
				this.$api.get(this, api.getBrandList, {
					deviceSn: devSn
				}).then((res) => {
					if (res.code === 0) {
						if (res.data && res.data.length > 0) {
							let brandList = res.data;

							// 默认取第一条
							this.brandId = brandList[0].brandId;
							this.brandName = brandList[0].brandName;
							sessionStorage.setItem("brandId", this.brandId);
							sessionStorage.setItem("brandName", this.brandName);

							this.brandList = brandList;

							this.getModelList(this.brandList[0].brandId, "origin");
						} else {
							this.$toast(this.$t("C.NoModelLists"));
						}
					}
				});
			},

			// 获取机型
			getModelList(brandId, tag) {
				let devSn = this.devSn || this.localDevSn;
				this.$api
					.get(this, api.getModelList, {
						deviceSn: devSn,
						brandId: brandId
					})
					.then((res) => {
						if (res.code === 0) {
							this.isLoading = false;
							this.modelList = [];
							if (res.data && res.data.length > 0) {
								let modelList = res.data;

								// 将数据存到热门里面
								this.topTenModelList = [];
								this.topTenModelList = modelList;

								if (tag === "origin") {
									// 默认取第一条
									this.modelId = modelList[0].modelId;
									this.modelName = modelList[0].modelNameCn;
									sessionStorage.setItem("modelId", this.modelId);
									sessionStorage.setItem("modelName", this.modelName);
									this.setModelId(this.modelId);
									this.setModelName(this.modelName);

									// 框图
									this.frameImg = modelList[0].image;

									// console.log("机型长: " + this.pm2px(modelList[0].length));
									// console.log("机型宽: " + this.pm2px(modelList[0].width));

									let frameHSc = 0; // 缩放后机型长
									let x = this.pm2px(modelList[0].length); // 原始机型长
									let y = this.phonenHeight; // 缩放后底图长
									let z = this.pm2px(this.mh); //原始底图长

									let frameWSc = 0; // 缩放后机型宽
									let x2 = this.pm2px(modelList[0].width); // 原始机型宽
									let y2 = this.phonenWidth; // 缩放后底图宽
									let z2 = this.pm2px(this.mw); // 原始底图宽

									frameHSc = (x * y) / z;
									frameWSc = (x2 * y2) / z2;

									// console.log("缩放后的机型长: " + frameHSc);
									// console.log("缩放后的机型宽: " + frameWSc);

									this.frameH = frameHSc;
									this.frameW = frameWSc;

									let whiteScopeW = this.pm2px(this.leftDistance); // 左移
									let whiteScopeT = -this.pm2px(this.topDistance); // 上移
									this.whiteScopeW = whiteScopeW;
									this.whiteScopeT = whiteScopeT;
								}

								//根据机型ID获取图库图片
								this.getPicList();

								this.modelList = modelList;
							}
						} else {
							this.$toast(this.$t("C.NoModelLists"));
						}
					});
			},

			// 监听子组件的切换品牌切换品牌
			changeBrand(val) {
				this.message = this.$t("C.Loading");
				this.isLoading = true;
				this.brandId = val.brandId;
				this.brandName = val.brandName;
				sessionStorage.setItem("brandId", this.brandId);
				sessionStorage.setItem("brandName", this.brandName);
				this.getModelList(this.brandId, "origin");
			},

			// 点击更换机型
			handleModel() {
				// 允许页面滚动
				TpScroll.allowScroll();
				this.setFalse();
				this.setModelCenter();
			},

			// 取消机型选择
			handleCancelModel() {
				// 禁止页面滚动
				TpScroll.disableScroll();
				this.setModelBottom();
				// 判断是否已经选择了图片
				if (this.$refs.mcanvas.dragArr.length <= 0) {
					//this.setTrue();
				}
			},

			// 确定机型选择
			handleConfirmModel(val, tag) {
				// 禁止页面滚动
				TpScroll.disableScroll();
				this.setModelBottom();
				this.brandId = val.brandId;
				if (tag === "top") {
					this.getModelList(val.brandId, "top");
				}
				
				//清空canvas画布中所有图层
				this.$refs.mcanvas.dragArr = [];
				//重新绘制
				this.$refs.mcanvas.draw();
				//清空显示
				this.clearImgOrText(2)

				this.modelId = val.modelId;
				this.modelName = val.modelName;
				sessionStorage.setItem("modelId", this.modelId);
				sessionStorage.setItem("modelName", this.modelName);
				this.setModelId(this.modelId);
				this.setModelName(this.modelName);

				// 框图
				this.frameImg = val.image;

				//console.log("机型长: " + this.pm2px(val.length));
				//console.log("机型宽: " + this.pm2px(val.width));

				let frameHSc = 0; // 缩放后机型长
				let x = this.pm2px(val.length); // 原始机型长
				let y = this.phonenHeight; // 缩放后底图长
				let z = this.pm2px(this.mh); //原始底图长

				let frameWSc = 0; // 缩放后机型宽
				let x2 = this.pm2px(val.width); // 原始机型宽
				let y2 = this.phonenWidth; // 缩放后底图宽
				let z2 = this.pm2px(this.mw); //原始底图宽

				frameHSc = (x * y) / z;
				frameWSc = (x2 * y2) / z2;

				//console.log("缩放后的机型长: " + frameHSc);
				//console.log("缩放后的机型宽: " + frameWSc);

				this.frameH = frameHSc;
				this.frameW = frameWSc;

				let whiteScopeW = this.pm2px(this.leftDistance); // 左移
				let whiteScopeT = -this.pm2px(this.topDistance); // 上移
				this.whiteScopeW = whiteScopeW;
				this.whiteScopeT = whiteScopeT;

				// 根据机型ID获取图库图片
				this.getPicList();

				// 判断是否已经选择了图片
				if (this.$refs.mcanvas.dragArr.length <= 0) {
					//this.setTrue();
				}
			},
			
			// 切换机型选择
			switchModel(val, tag) {
				// 禁止页面滚动
				TpScroll.disableScroll();
				if (tag === "top") {
					this.getModelList(val.brandId, "top");
				}
				
				//清空canvas画布中所有图层
				this.$refs.mcanvas.dragArr = [];
				//重新绘制
				this.$refs.mcanvas.draw();
				//清空显示
				this.clearImgOrText(2)

				this.modelId = val.modelId;
				this.modelName = val.modelName;
				sessionStorage.setItem("modelId", this.modelId);
				sessionStorage.setItem("modelName", this.modelName);
				this.setModelId(this.modelId);
				this.setModelName(this.modelName);

				// 框图
				this.frameImg = val.image;

				//console.log("机型长: " + this.pm2px(val.length));
				//console.log("机型宽: " + this.pm2px(val.width));

				let frameHSc = 0; // 缩放后机型长
				let x = this.pm2px(val.length); // 原始机型长
				let y = this.phonenHeight; // 缩放后底图长
				let z = this.pm2px(this.mh); //原始底图长

				let frameWSc = 0; // 缩放后机型宽
				let x2 = this.pm2px(val.width); // 原始机型宽
				let y2 = this.phonenWidth; // 缩放后底图宽
				let z2 = this.pm2px(this.mw); //原始底图宽

				frameHSc = (x * y) / z;
				frameWSc = (x2 * y2) / z2;

				//console.log("缩放后的机型长: " + frameHSc);
				//console.log("缩放后的机型宽: " + frameWSc);

				this.frameH = frameHSc;
				this.frameW = frameWSc;

				let whiteScopeW = this.pm2px(this.leftDistance); // 左移
				let whiteScopeT = -this.pm2px(this.topDistance); // 上移
				this.whiteScopeW = whiteScopeW;
				this.whiteScopeT = whiteScopeT;

				// 根据机型ID获取图库图片
				this.getPicList();

				// 判断是否已经选择了图片
				if (this.$refs.mcanvas.dragArr.length <= 0) {
					//this.setTrue();
				}
			},

			// ------------------------------------------------- 品牌机型 END

			// ------------------------------------------------- 文字 START
			// 文字
			openText() {
				// 遍历canvas画布中所有图层，判断是否有图片
				let hasPic = false;
				if (this.$refs.mcanvas.dragArr && this.$refs.mcanvas.dragArr.length > 0) {
					let arr = this.$refs.mcanvas.dragArr;
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}

				if (hasPic) {
					// 关闭功能组件
					this.showMoreHandle = false;

					// 获取字体
					if (this.fontFamily.length === 0) {
						this.getFont();
					}

					// 文字弹框器
					this.$refs.textWrapper.style.height = "auto";
					this.textDialog = true;

					// 关闭其他弹窗
					this.showModel = false;
					this.setFalse();

					this.spriteArr.forEach((item) => {
						if (item.selected && item.type === 1) {
							this.inputtext = item.filltext;
							this.family = item.fontfamily;
							this.fontColor = item.fillstyle;
						}
					});

				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 获取字体列表
			getFont() {
				this.$api.get(this, api.getFontList).then((res) => {
					if (res.success) {
						if (res.data && res.data.length > 0) {
							this.fontFamily = res.data;
							this.family = this.family ? this.family : this.fontFamily[0].englishName;
							this.initFont(this.fontFamily[0], this.family);
							this.fontColor = this.colors.hex;
						} else {
							this.message = "";
							this.isLoading = false;
							this.$toast.fail(res.errMessage);
						}
					}
				});
			},

			// 引入字体文件，并初始化字体
			initFont(item, fontFamily) {
				let style = document.createElement("style");
				style.type = "text/css";
				let str =
					'@font-face {font-family: "' +
					item.englishName +
					'"; src: url("' +
					item.fontFile +
					'"); font-weight: normal; font-style: normal;}';
				style.innerText = str;
				document.getElementsByTagName("head")[0].appendChild(style);

				let dom = document.createElement("div");
				dom.style.fontFamily = fontFamily;
				dom.style.fontSize = "14px";
				dom.style.opacity = "0";
				dom.innerHTML = "test";
				document.getElementById("phone").appendChild(dom);
			},

			// 关闭文字弹框
			closeTextDialog() {
				this.textDialog = false;
				this.showSelct = false;
			},

			// 添加文字高度自适应
			focusInput() {
				if (window.screen.width > 500) {
					this.$refs.textWrapper.style.height = 350 + "px";
				}
			},
			toggle() {
				this.$refs.textWrapper.style.height = "auto";
				this.showSelct = !this.showSelct;
			},

			// 添加文字
			addText() {
				if (this.validateText()) {
					if (this.textData.posX === null) {
						// 获取中心坐标
						this.textData.posX = window.screen.width / 2;
						this.textData.posY = this.wheight / 2;
						this.textData.posuX = this.phonenWidth / 2;
						this.textData.posuY = this.phonenHeight / 2;
						this.textData.sizeW = 20;
						this.textData.sizeH = 20;
						this.textData.rotate = 0;
						this.index = this.index === null ? this.index : null;
					}
					let textObj = {
						url: "",
						x: this.textData.posX,
						y: this.textData.posY,
						ux: this.textData.posuX,
						uy: this.textData.posuY,
						width: this.textData.sizeW,
						height: this.textData.sizeH,
						initW: this.textData.sizeW,
						initH: this.textData.sizeH,
						minW: 10,
						minH: 10,
						fillstyle: this.fontColor,
						filltext: this.inputtext,
						fontsize: this.textData.sizeH,
						fontfamily: this.family,
						type: 1, // 文字
						picType: 2, // 0和1是图片，如果出现了其他的数字就是文字，文字不能往下执行
						rotate: this.textData.rotate,
						selected: true,
						index: this.index
					};
					this.spriteArr = [];
					this.spriteArr.push(textObj);
					this.textDialog = false;
				}
			},

			// 字体选择
			handleSelect(item) {
				this.showSelct = false;
				this.family = item.englishName;
				this.initFont(item, this.family);
			},

			// 获取字体的名字
			getFamilyName(val) {
				let name;
				let bLang = window.localStorage.getItem('bLang');
				this.fontFamily.forEach((item) => {
					if (item.englishName === val) {
						if (bLang === "zh-cn") {
							name = item.name;
						} else {
							name = item.englishName;
						}
					}
				});
				return name;
			},

			// 选择元素
			selectImgOrText(sprite, index) {
				if (sprite.type === 0 && index === null) {
					this.rotateDisable = false; // 选择图片启用滑块
					this.imgData.posX = sprite.x;
					this.imgData.posY = sprite.y;
					this.imgData.posuX = sprite.ux;
					this.imgData.posuY = sprite.uy;
					this.imgData.sizeW = sprite.w;
					this.imgData.sizeH = sprite.h;
					this.imgData.rotate = sprite.rotate;
					this.imgData.linew = sprite.linew;
					this.imgData.lineh = sprite.lineh;
					this.textData.posX = null;
				} else if (sprite.type === 1) {
					// 切换至文字
					this.rotateDisable = true; // 选择文字禁用滑块
					this.textData.posX = sprite.x;
					this.textData.posY = sprite.y;
					this.textData.posuX = sprite.ux;
					this.textData.posuY = sprite.uy;
					this.textData.sizeW = sprite.w;
					this.textData.sizeH = sprite.h;
					this.family = sprite.fontfamily;
					this.inputtext = sprite.filltext;
					this.fontColor = sprite.fillstyle;
					this.textData.rotate = sprite.rotate;
					this.index = index;
					this.$refs.mcanvas.draw(); // 重绘，以免删减文字错位
				}
			},

			// 文字校验
			validateText() {
				if (this.family === "") {
					this.$toast(this.$t("C.PleaseChoose"));
					return false;
				}
				if (this.inputtext === "") {
					this.$toast(this.$t("C.PleaseChooseText"));
					return false;
				}
				return true;
			},

			// 清空文字
			clearImgOrText(type) {
				// 0--图片  1--文字  2--切换机型全部清空
				if (type === 0) {
					this.imgData.posX = null;
					this.wallpImg = "";
					this.dpiValue = 0;
					//初始化旋转滑块值和禁用滑块
					this.rotateValue = 0;
					this.rotateDisable = true;
				} else if (type === 1) {
					this.textData.posX = null;
					this.inputtext = "";
					this.fontColor = this.colors.hex;
				} else if (type === 2) {
					this.spriteArr = [];
					this.imgData.posX = null;
					this.wallpImg = "";
					this.dpiValue = 0;
					//初始化旋转滑块值和禁用滑块
					this.rotateValue = 0;
					this.rotateDisable = true;
					this.textData.posX = null;
					this.inputtext = "";
					this.fontColor = this.colors.hex;
				}
			},
			// ------------------------------------------------- 文字 END

			// ------------------------------------------------- 其他功能 START
			//其他功能
			openCustomDialog() {
				// 遍历canvas画布中所有图层，判断是否有图片
				let hasPic = false;
				if (this.$refs.mcanvas.dragArr && this.$refs.mcanvas.dragArr.length > 0) {
					let arr = this.$refs.mcanvas.dragArr;
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}

				if (hasPic) {
					// 关闭功能组件
					this.showMoreHandle = false;

					// 自定义图片编辑弹框器
					this.customDialog = true;

					// 关闭其他弹窗
					this.showModel = false;
					this.setFalse();

				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			closeCustomDialog() {
				this.customDialog = false;
			},

			// 点击复原功能
			clickRestoreIcon() {
				let hasPic = false;
				let arr = this.$refs.mcanvas.dragArr;
				if (arr && arr.length > 0) {
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}
				if (hasPic) {
					let item = arr[0];
					if (item && item.selected === true) {
						let val1 = this.originWallpImg;
						let val2 = this.picId;
						let val3 = 0;
						let val4 = 0;
						if ((val1 && val1.indexOf("data:") === 0)) {
							val4 = 0;
						} else {
							val4 = 1;
						}
						this.changeSelect(val1, val2, val3, val4);
						var that = this;
						setTimeout(function() {
							that.$toast(that.$t("C.SuccessRestore"));
						}, 600)
						this.$refs.mcanvas.draw();
					} else {
						this.$toast(this.$t("C.PicChooseTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 点击旋转功能
			clickRotateIcon() {
				let hasPic = false;
				let arr = this.$refs.mcanvas.dragArr;
				if (arr && arr.length > 0) {
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}
				if (hasPic) {
					let item = arr[0];
					if (item && item.selected === true) {
						this.showAllCustom = false;
						this.pictureEditTitle = this.$t("C.PictureEdit") + " - " + this.$t("C.Rotate");
						this.showRotateSlider = true;
					} else {
						this.$toast(this.$t("C.PicChooseTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 旋转15度
			clickRotateIconNum(tag, num) {
				let hasPic = false;
				let arr = this.$refs.mcanvas.dragArr;
				if (arr && arr.length > 0) {
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}

				if (hasPic) {
					let item = arr[0];
					if (item && item.selected === true) {
						if (tag === 'left') {
							item.rotate = item.rotate - num;
						} else {
							item.rotate = item.rotate + num;
						}
						this.$refs.mcanvas.draw();
					} else {
						this.$toast(this.$t("C.PicChooseTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 滑块旋转更新
			rotateSliderChange(val) {
				let hasPic = false;
				let arr = this.$refs.mcanvas.dragArr;
				if (arr && arr.length > 0) {
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}

				if (hasPic) {
					let item = arr[0];
					if (item && item.selected === true) {
						if (val === 0) {
							item.rotate = 0;
						} else {
							item.rotate = item.rotate + val;
						}
						this.$refs.mcanvas.draw();
					} else {
						this.$toast(this.$t("C.PicChooseTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// tag 0 : 水平翻转
			// tag 1 : 垂直翻转  
			clickFlipIconNum(tag) {
				let hasPic = false;
				let arr = this.$refs.mcanvas.dragArr;
				if (arr && arr.length > 0) {
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}
				if (hasPic) {
					let item = arr[0];
					if (item && item.selected === true) {
						if (tag == 0) {
							if (this.flipHorizontal) {
								this.flipHorizontal = false;
								this.$toast(this.$t("C.FlipHorizontalNo"));
								this.flipHorizontalImage =
									"https://print-oss.rockb2b.com/a_big_h5_resource/icon/flipHorizontal-no.png";
							} else {
								this.flipHorizontal = true;
								this.$toast(this.$t("C.FlipHorizontalYes"));
								this.flipHorizontalImage =
									"https://print-oss.rockb2b.com/a_big_h5_resource/icon/flipHorizontal.png";
							}

						}
						if (tag == 1) {
							if (this.flipVertical) {
								this.flipVertical = false;
								this.$toast(this.$t("C.FlipVerticalNo"));
								this.flipVerticalImage =
									"https://print-oss.rockb2b.com/a_big_h5_resource/icon/flipVertical-no.png";
							} else {
								this.flipVertical = true;
								this.$toast(this.$t("C.FlipVerticalYes"));
								this.flipVerticalImage =
									"https://print-oss.rockb2b.com/a_big_h5_resource/icon/flipVertical.png";
							}
						}
					} else {
						this.$toast(this.$t("C.PicChooseTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 复原滑块
			restoreRotateSlider() {
				let hasPic = false;
				let arr = this.$refs.mcanvas.dragArr;
				if (arr && arr.length > 0) {
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							hasPic = true;
						}
					});
				}

				if (hasPic) {
					let item = arr[0];
					if (item && item.selected === true) {
						this.rotateValue = 0;
						item.rotate = 0;
						this.$toast(this.$t("C.SuccessRestoreRotate"));
						this.$refs.mcanvas.draw();
					} else {
						this.$toast(this.$t("C.PicChooseTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 关闭滑块
			closeRotateSlider() {
				this.showRotateSlider = false;
				this.showAllCustom = true;
				this.pictureEditTitle = this.$t("C.PictureEdit");
			},
			// ------------------------------------------------- 其他功能 END

			// 点击下一步
			handleSubmit() {
				// 遍历canvas画布中所有图层，判断是否有图片
				let hasPic = false;
				if (this.$refs.mcanvas.dragArr && this.$refs.mcanvas.dragArr.length > 0) {
					let arr = this.$refs.mcanvas.dragArr;
					arr.forEach((item) => {
						if (Number(item.picType) === 0 || Number(item.picType) === 1) {
							// 有图片
							hasPic = true;
						}
					});
				}
				if (hasPic) {
					if (this.modelId) {
						this.message = this.$t("C.PicMake");
						this.isLoading = true;
						this.$refs.mcanvas.make();
					} else {
						this.$toast(this.$t("C.ModelTips"));
					}
				} else {
					this.$toast(this.$t("C.PicTips"));
				}
			},

			// 定制成功并跳转到下一个页面
			submit(imgUrl, pdfUrl) {
				localStorage.setItem("isHT", 1);
				this.$router.push({
					path: "/preview",
					query: {
						diyPic: imgUrl,
						diyPdf: pdfUrl
					}
				});
			},

			// mm转px
			pm2px(d) {
				var iswindow = /windows|win32|win64/i.test(navigator.userAgent);
				if (iswindow) {
					return Math.round((d * 96) / 25.4);
				} else {
					return Math.round((d * 72) / 25.4);
				}
			},

			// 提示
			handleLoading(isLoading, message) {
				this.message = message;
				this.isLoading = isLoading;
			},

			// 返回上一页
			handleBack() {
				this.$router.replace("/home");
			},

			// 全局数据
			...mapMutations({
				setPicId: "SET_PICID",
				setModelId: "SET_MODELID",
				setModelName: "SET_MODELNAME",
			})
		},
		watch: {
			colors(value) {
				// 字体颜色
				this.fontColor = value.hex;
			},


		}
	};
</script>

<style lang="stylus" scoped>
	@import '~common/styles/variable.styl';
	@import '~common/styles/mixin.styl';

	.page-container {
		input {
			-webkit-appearance: auto;
		}

		background-color: $color-bg-white;

		//border: 1px solid red;
		.phone-t {
			height: 100%;
			background-color: $color-bg-white;
			border-radius: 0 0 $radius-xl $radius-xl;
		}

		.phone-wrapper {
			position: relative;
			overflow: hidden;
			margin-top: 30px;
			z-index: 99;
		}

		.print-result {
			position: fixed;
			top: 200px;
			left: 35px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 1em;
			height: 107px;
			font-size: $font-sm;
			color: $color-font-grey;
			line-height: 15px;

			&.print-en {
				top: 240px;
				left: -25px;
				display: block;
				width: auto;
				height: auto;
				transform: rotate(90deg);

				.result {
					margin-left: 20px;
				}
			}

			.result {
				color: $color-orange;
			}
		}

		// 相册
		.handle-wrap {
			position: fixed;
			top: 276px;
			right: 0;
			height: 48px;
			z-index: 101;

			.handle-list {
				position: absolute;
				top: 0;
				right: 0;
				width: 60px;
				height: 48px;
				background-color: $color-bg-main-lighter;
				border-radius: 48px 0 0 48px;
				transition: width 0.25s linear;

				>.sprite-icon {
					position: absolute;
					top: 50%;
					left: 16px;
					transform: translateY(-50%);
				}

				&.more1 {
					width: 140px;
				}

				&.more2 {
					width: 200px;
				}
			}

			.more-handle {
				display: flex;
				height: 100%;
				padding: 0 $spacing-sm 0 38px;

				.van-icon-arrow {
					position: absolute;
					top: 50%;
					left: $spacing-lg;
					font-size: $font-base;
					transform: translateY(-50%);
				}

				.handle-item {
					flex: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					position: relative;
					top: 2px;
					height: 40px;
					font-size: $font-sm;

					&.disable {
						color: $color-font-grey;
					}

					.icon-paster {
						top: 2px;
					}

					p {
						margin-top: 4px;
						transform: scale(0.9);
					}
				}
			}
		}

		// 机型
		.model-wrap {
			display: flex;
			padding: 0 $spacing-base 0 $spacing-lg;
			justify-content: center;
			position: relative;
			z-index: 100;

			li {
				display: flex;
				align-items: center;
				padding: 7px 10px 5px 15px;
				position: relative;
				font-size: $font-base;
				color: $color-font-base;
				background-color: rgbaMain(0.2);
				border-radius: 30px;
			}

			p {
				no-wrap();
				align(center);
				padding-right: 5px;
				display: inline-block;
			}

			.color {
				position: relative;
				top: 50%;
				display: inline-block;
				transform: translateY(-50%) scale(0.85);
			}
		}

		.mask {
			mask();
			z-index: 100;
		}

		.img-wrap {
			line-height: 0;
			background-color: $color-bg-white;

			>>>img {
				width: 100%;
			}
		}

		.no-data {
			padding-bottom: 65px;
			background-color: $color-bg-white;
		}

		.model-wrapper {
			transition: all 0.3s;
			mask();
			background-color: transparent;
			z-index: 101;

			.model {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 400px;
				background-color: $color-bg-white;
				border-radius: $radius-base $radius-base 0 0;
				overflow: hidden;
			}
		}

		.picture-wrapper {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 101;
			transition: all 0.3s;

			>>>.no-data-wrap {
				position: relative;
				width: 100%;
				height: 100%;

				.no-data {
					position: relative;
					top: 30px;
					transform: translate(-50%, 0);
				}
			}

			>>>.img-list {
				overflow-y: hidden;
			}

			&.shadow {
				transition: all 0.3s;

				.handle-pic {
					height: 50px;
					box-shadow: $color-shadow;

					.photoSelectA {
						font-size: 15px;
						color: #000000;
						float: left;
						position: relative;
						left: 20px;
						top: 5px;
					}

					.photoSelectB {
						font-size: 15px;
						color: $color-main;
						float: right;
						position: relative;
						right: 20px;
						top: 5px;
					}
				}

				>>>.img-list {
					overflow-y: scroll;
				}

				>>>.no-data-wrap {
					.no-data {
						position: absolute;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}

			.picture-data {
				position: relative;
				top: -1px;
				height: 100%;
				background-color: $color-bg-white;
			}

			.handle-pic {
				position: relative;
				padding: 12px 0 1px;
				align(center);
				background-color: $color-bg-white;
				border-radius: $radius-xl $radius-xl 0 0;

				.line {
					position: relative;
					width: 46px;
					height: 22px;
					display: inline-block;

					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border-radius: 14px;
						border: 1px solid $color-line-opacity;
					}
				}

				.line-arrow-up {
					background-image: url('../../common/images/icon_down_2.png');
					background-repeat: no-repeat;
					background-size: 18px 18px;
					background-position: center center;
					transform: rotate(180deg);
					animation: upAndDown 0.8s steps(1, start) infinite;
					z-index: 10;
				}

				@keyframes upAndDown {

					0%,
					100% {
						background-image: url('../../common/images/icon_down_1.png');
					}

					50% {
						background-image: url('../../common/images/icon_down_2.png');
					}
				}

				.line-arrow-down {
					background: url('../../common/images/icon_down_1.png') no-repeat center center;
					background-size: 18px 18px;
				}

				.line-arrow-down-close {
					padding-top: 6px;
					padding-left: 2px;
					font-size: 13px;
				}
			}
		}

		// 文字样式
		.text-wrapper {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			padding: $spacing-lg $spacing-base;
			background-color: $color-bg-white;
			border-radius: $radius-lg $radius-lg 0 0;
			overflow-y: scroll;
			z-index: 101;
			-webkit-overflow-scrolling: touch;
			box-shadow: $color-shadow;

			&::-webkit-scrollbar {
				display: none;
			}

			.title {
				lineHeight(25px);

				.text {
					display: inline-block;
					margin-left: 15px;
					font-size: 16px;
					color: #4A4A4A;
				}

				.icon {
					display: inline-block;
					font-size: 20px;
					float: right;
				}
			}

			.input {
				width: 100%;
				margin-top: 30px;
				padding: 8px 16px;
				border-radius: 6px;
				box-sizing: border-box;
				border: 0px solid #ffffff;
				background-color: rgba(155, 155, 155, 0.09);

				>>>.van-cell__value {
					lineHeight(20px);
				}
			}

			.select-box {
				position: relative;

				&::after {
					position: absolute;
					top: -6px;
					left: 10px;
					margin-right: 3px;
					border-top-width: 0;
					border-bottom-color: #EBEEF5;
					background-color: red;
				}

				.select {
					width: 100%;
					margin-top: 20px;
					lineHeight(32px);
					padding: 0 $spacing-base;
					border-radius: $radius-sm;
					border: none;
					box-sizing: border-box;
					background-color: $color-bg-input;
					-webkit-appearance: none;
					-moz-appearance: none;
					appearance: non;
					cursor: pointer;
					outline: none;
				}

				.select-list {
					position: absolute;
					top: 40px;
					left: 0;
					width: 100%;
					padding: 0 $spacing-base;
					border: 1px solid $color-border;
					border-radius: $radius-xs;
					background-color: $color-bg-white;
					-webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
					box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					float: left;
					z-index: 22;
					text-align: left;
					font-size: 14px;

					.select-item {
						lineHeight(32px);
					}
				}
			}

			.el-radio-group {
				display: flex;
				flex-wrap: wrap;
			}

			>>>.el-radio {
				position: relative;
				display: inline-block;
				width: 16.6%;
				margin: 28px 0 0 0;

				.el-radio__input {
					display: none;
				}

				.el-radio__label {
					padding: 0;
				}

				.color {
					display: inline-block;
					padding: 0;
					width: 18px;
					height: 18px;
					border-radius: 50%;
				}

				.iscolor {
					position: relative;
					display: inline-block;
					padding: 0;
					width: 18px;
					height: 18px;
					border-radius: 50%;

					&:after {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						width: 24px;
						height: 24px;
						border-radius: 50%;
						transform: translate3d(-50%, -50%, 0);
						border: 1px solid;
						border-color: inherit;
					}
				}
			}

			.color-box {
				width: 100%;
				margin: 40px 0 50px;
				display: flex;

				.color {
					display: inline-block;
					margin-top: 1px;
					width: 40px;
					height: 40px;
					border-radius: $radius-circle;
					box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
				}

				.vc-slider {
					flex: 1;
					margin-left: 30px;

					>>>.vc-slider-hue-warp {
						height: 12px;
					}

					>>>.vc-slider-hue-warp,
					>>>.vc-slider-swatches {
						box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
					}
				}
			}

			.btn-box {
				display: flex;
				flex-wrap: nowrap;
				align-content: flex-center;
				align-items: center;
				justify-content: space-around;
				margin-top: 28px;

				.btn {
					display: inline-block;
					width: 140px;
					lineHeight(36px);
					font-size: $font-base;
					text-align: center;
					border-radius: 36px;
				}

				.confirm-btn {
					color: #ffffff;
				}
			}
		}

		// 底部按钮栏
		.nav-wrapper {
			flex: none;
			display: flex;
			height: 70px;
			padding: 0 5px;
			align-items: center;
			background: rgba(255, 255, 255, 0.9);
			box-shadow: 0 2px 12px 0 rgba(235, 232, 232, 0.5);
			z-index: 98;
			width: 100%;
			position: fixed;
			bottom: 0;

			.nav-list {
				display: flex;
				flex: 1;

				.nav-item {
					flex: 1;
					text-align: center;

					input {
						display: none;
					}

					.icon {
						display: inline-block;
						font-size: 24px;
						color: #333333;
						width: 24px;
						height: 24px;
						margin-bottom: 10px;
						background-size: 24px 24px;
						background-repeat: no-repeat;
					}

					.text {
						margin-top: -8px;
						font-size: 10px;
						text-align: center;
						color: #4A4A4A;
					}
				}
			}

			.btn-submit {
				display: inline-block;
				width: 40%;
				height: 40px;
				margin: 0 5px;
				line-height: 40px;
				font-size: 16px;
				color: #ffffff;
				text-align: center;
				border-radius: 20px;
				background: #15BED6;

				.text {
					font-size: 14px;
					line-height: 40px;
				}
			}
		}

		// 更多功能弹框
		.custom-wrapper {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			padding: $spacing-lg $spacing-base;
			background-color: $color-bg-white;
			//border-radius: $radius-lg $radius-lg 0 0;
			overflow-y: scroll;
			z-index: 101;
			-webkit-overflow-scrolling: touch;
			box-shadow: $color-shadow;
			height: 200px;

			&::-webkit-scrollbar {
				display: none;
			}

			.title {
				lineHeight(25px);

				.text {
					display: inline-block;
					margin-left: 15px;
					font-size: 16px;
					color: #4A4A4A;
				}

				.icon {
					display: inline-block;
					font-size: 20px;
					float: right;
				}
			}

			.custom-wrapper-box {
				height: auto;
				display: flex;
				align-items: center;
				margin-top: 15px;

				.box-rotate {
					width: 20%;

					.box-rotate-img {
						width: 35%;
						height: auto;
					}

					.box-rotate-title {
						font-size: 12px;
						margin-top: 5px;
					}
				}
			}

			.slider-box {
				height: auto;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 25px;

				.slider-box-rotate {
					width: 100%;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;

					.rotate-slider {
						width: 90%;
						height: 50px;
					}

					.rotate-slider-return {
						display: flex;
						flex-wrap: nowrap;
						align-content: flex-center;
						align-items: center;
						justify-content: space-around;
						margin-top: 10px;

						.btn {
							display: inline-block;
							width: 140px;
							lineHeight(36px);
							font-size: $font-base;
							text-align: center;
							border-radius: 36px;
						}

						.restore-btn {
							border-radius: 50%;
							width: 33px;
							height: 33px;
							line-height: 33px;
							text-align: center;
							color: #ffffff;
							font-size: 9px;
							background-color: #00bed6;
							margin-right: 20px;
						}
					}
				}
			}
		}
	}

	// 机型弹框滑动效果
	.fadeModel-enter-active,
	.fadeModel-leave-active {
		transition: all 0.3s;
	}

	.fadeModel-enter,
	.fadeModel-leave-to {
		transform: translate3d(0, 100%, 0);
	}

	// 文字弹框滑动效果
	.fadeText-enter-active,
	.fadeText-leave-active {
		transition: all 0.3s;
	}

	.fadeText-enter,
	.fadeText-leave-to {
		transform: translate3d(0, 100%, 0);
	}

	// 其他功能弹框滑动效果
	.fadeCustom-enter-active,
	.fadeCustom-leave-active {
		transition: all 0.3s;
	}

	.fadeCustom-enter,
	.fadeCustom-leave-to {
		transform: translate3d(0, 100%, 0);
	}


	// 相册协议弹框
	.flexible-dialog {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;

		.flexible-dialog-content {
			position: absolute;
			width: 85%;
			background-size: 100% 100%;
			height: auto;
			background-color: #ffffff;
			border-radius: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;

			.flexible-cotent-middle-title {
				width: 85%;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 20px;
				font-size: 20px;
				color: #333333;
				letter-spacing: 1px;
				font-weight: bold;
			}

			.flexible-cotent-middle-info {
				width: 85%;
				display: flex;
				justify-content: space-between;
				font-size: 15px;
				color: #666666;
				letter-spacing: 0.8px;
				margin-top: 5px;
				line-height: 22.5px;
				text-align: center;
			}

			.flexible-cotent-bottom {
				width: 100%;
				height: 60px;
				line-height: 60px;
				text-align: center;
				display: flex;
				justify-content: center;
				font-size: 18px;
				color: #ffffff;
				margin-top: 30px;

				.flexible-cotent-bottom-left,
				.flexible-cotent-bottom-right {
					width: 50%;
					border-top: 1px solid #ececec;
				}

				.flexible-cotent-bottom-left {
					color: #999999;
					border-right: 1px solid #ececec;
				}

				.flexible-cotent-bottom-right {
					color: #333333;
				}
			}
		}
	}
</style>
